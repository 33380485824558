import { SyntheticEvent, useEffect, useState } from "react";
import {
  CurrentUserType,
  useAuth,
} from "../../hooks/context/authContext/authContext";
import { updateUserService } from "../../services/userService";
import UserPostList from "../../components/user-post-list/user-post-list";
import { Link, Outlet } from "react-router-dom";
import CreateUser from "../../components/action-page/components/create-user/create-user";
import { Toaster } from "react-hot-toast";

const Profile = () => {
  const { getUserCurrentUser } = useAuth();

  useEffect(() => {
    getUserCurrentUser();
  }, []);

  return (
    <div className="profile">
      <div className="form-section">
        <CreateUser />
      </div>
      <div className="account-management">
        <div className="profile-header">
          <h1>Your Bulletins</h1>
        </div>
        <UserPostList />
        <div className="profile-header">
          <h1>Account Management</h1>
        </div>
        <div className="links">
          <p>
            Want to change your password?{" "}
            <Link className="link" to={"action/reset-password"}>
              Click here
            </Link>{" "}
            to reset it.
          </p>
          {/* <p>
            Want to log out?{" "}
            <button onClick={() => logout()} className="link">
              Click here
            </button>
            .
          </p> */}
        </div>
      </div>
      <Outlet />
      <Toaster
        toastOptions={{
          style: {
            backgroundColor: "#598B54",
            color: "white",
            fontWeight: "bold",
          },
        }}
      />
    </div>
  );
};
export default Profile;
