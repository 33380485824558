import OpaqueBackground from "../../components/opaque-background";
import oldHouseImg from "../../assets/img/oldHouse.png";
import hillcrestMap from "../../assets/img/hillcrest-estates-map.png";
import Header from "../../components/header";
import SearchAddress from "../../components/search-address";

const Home = () => {
  return (
    <div className="home">
      <section className="container">
        <OpaqueBackground>
          <Header />
          <section className="content-section">
            <div className="content-map">
              <div className="info-wrapper">
                <p className="label">Come on in</p>
                <h1>Hillcrest Estates</h1>
                <p className="paragraph">
                  It’s great to see you, neighbor. To begin, search for your
                  home address using the search bar below. If you already have
                  an account, you can log in here.
                </p>
                <SearchAddress title="Enter Your Address"></SearchAddress>
              </div>
              {/* <div className="search-address">
              <label htmlFor="search"> Enter your address</label>
              <label className="search-bar" htmlFor="input">
                <input
                  id="input"
                  name="input"
                  type="text"
                  placeholder="6405 Belmead Drive"
                />
                <span className="circle">
                  <FontAwesomeIcon icon={faMagnifyingGlass} />
                </span>
              </label>
            </div> */}
              {/* <img
                className="map"
                src={hillcrestMap}
                alt="hillcrest estates map"
              /> */}
            </div>
          </section>
        </OpaqueBackground>
      </section>
      {/* <img src={oldHouseImg} alt="old house" /> */}
    </div>
  );
};
export default Home;
