import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import ActionPage from "../../components/action-page";
import AnnouncementDescription from "../../components/action-page/components/announcement-description";
import BulletinDescription from "../../components/action-page/components/bulletin-description";
import CreateBulletin from "../../components/action-page/components/create-bulletin";
import ProtectedRoute from "../../components/protected-route";
import Sidebar from "../../components/sidebar";
import { useAuth } from "../../hooks/context/authContext/authContext";
import AboutUs from "../about-us";
import Announcements from "../announcements";
import Dashboard from "../dashboard";
import DeedRestrictions from "../deed-restrictions";
import Profile from "../profile";
import AdminAnnouncements from "../admin-announcements/admin-announcements";
import CreateAnnouncement from "../../components/action-page/components/create-announcement";
import AdminAds from "../admin-ads/admin-ads";
import CreateAd from "../../components/action-page/components/create-ad";
import AdminPosts from "../admin-bulletins/admin-bulletins";
import InsertAd from "../../components/action-page/components/insert-ad/insert-ad";
import AdminUsers from "../admin-users/admin-users";
import CreateUser from "../../components/action-page/components/create-user";
import ResetPassword from "../../components/action-page/components/reset-password/reset-password";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faXmark } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";

const Layout = () => {
  const location = useLocation();
  const { currentUser } = useAuth();
  const [sidebarOpen, setSidebarOpen] = useState(false);
  useEffect(()=>{
    // if(location.pathname!=="")
    setSidebarOpen(false)
  }, [location.pathname])

  return (
    <div className="layout">
      <div
        className="menu-button"
        onClick={() => setSidebarOpen((prev) => !prev)}
      >
        {sidebarOpen ? (
          <FontAwesomeIcon icon={faXmark} />
        ) : (
          <FontAwesomeIcon icon={faBars} />
        )}
      </div>
      <div className={`side-bar ${sidebarOpen ? "show-sidebar" : ""}`}>
        <Sidebar />
      </div>
      <div className={`page`}>
        <Routes>
          <Route element={<ProtectedRoute isAllowed={!!currentUser} />}>
            <Route path="/dashboard" element={<Dashboard />}>
              <Route path="action" element={<ActionPage />}>
                <Route path="new-bulletin" element={<CreateBulletin />}></Route>
                <Route
                  path="bulletin/:uuid"
                  element={<BulletinDescription />}
                ></Route>
                <Route
                  path="edit-bulletin/:uuid"
                  element={<CreateBulletin />}
                ></Route>
              </Route>
            </Route>
            <Route path="/announcements" element={<Announcements />}>
              <Route path="action" element={<ActionPage />}>
                <Route
                  path="announcement/:uuid"
                  element={<AnnouncementDescription />}
                ></Route>
              </Route>
            </Route>
            <Route
              path="/deed-restrictions"
              element={<DeedRestrictions />}
            ></Route>
            <Route path="/about" element={<AboutUs />}></Route>
            <Route path="/profile" element={<Profile />}>
              <Route path="action" element={<ActionPage />}>
                <Route
                  path="edit-bulletin/:uuid"
                  element={<CreateBulletin />}
                ></Route>
                <Route
                  path="reset-password"
                  element={<ResetPassword />}
                ></Route>
              </Route>
            </Route>
            <Route path="/admin/bulletin" element={<AdminPosts />}>
              <Route path="action" element={<ActionPage />}>
                <Route path="insert-ad" element={<InsertAd />}></Route>
                <Route path="new-post" element={<CreateBulletin />}></Route>
                <Route
                  path="edit-post/:uuid"
                  element={<CreateBulletin />}
                ></Route>
              </Route>
            </Route>
            <Route path="/admin/ad" element={<AdminAds />}>
              <Route path="action" element={<ActionPage />}>
                <Route path="new-ad" element={<CreateAd />}></Route>
                <Route path="edit-ad/:uuid" element={<CreateAd />}></Route>
              </Route>
            </Route>
            <Route path="/admin/announcement" element={<AdminAnnouncements />}>
              <Route path="action" element={<ActionPage />}>
                <Route
                  path="new-announcement"
                  element={<CreateAnnouncement />}
                ></Route>
                <Route
                  path="edit-announcement/:uuid"
                  element={<CreateAnnouncement />}
                ></Route>
              </Route>
            </Route>
            <Route path="/admin/user" element={<AdminUsers />}>
              <Route path="action" element={<ActionPage />}>
                <Route path="new-user" element={<CreateUser />}></Route>
                <Route path="edit-user/:uuid" element={<CreateUser />}></Route>
              </Route>
            </Route>
          </Route>
          <Route path="*" element={<Navigate to="dashboard" replace />} />
        </Routes>
      </div>
    </div>
  );
};
export default Layout;
