import "./column-insert.scss";
import { useGlobalContext } from "../../../../hooks/context/globalContext/globalContext";
import { useState } from "react";

export const ColumnInsertAd = ({
  postUUID,
  order,
  adUUID,
}: {
  postUUID: string;
  order: number;
  adUUID: string;
}) => {
  const { selectedPost, updateAd } = useGlobalContext();
  const [loading, setLoading] = useState<boolean>(false);
  const handleInserAdChange: React.ChangeEventHandler<HTMLInputElement> = ({
    target,
  }) => {
    setLoading(true);
    updateAd(adUUID, {
      post_uuid: selectedPost?.uuid,
      order: Number(target.value),
    });
    setLoading(false);
  };
  return (
    <>
      <div className="table-subcolumn">
        <input
          disabled={loading}
          id={`insert-before-${adUUID}`}
          type="radio"
          value={-1}
          checked={postUUID === selectedPost?.uuid && order == -1}
          onChange={handleInserAdChange}
        />
        <label htmlFor={`insert-before-${adUUID}`}>
          <div className="radio-button">Before</div>
        </label>
      </div>
      <div className="table-subcolumn">
        <input
          id={`insert-after-${adUUID}`}
          type="radio"
          value={1}
          checked={postUUID === selectedPost?.uuid && order == 1}
          onChange={({ target }) =>
            updateAd(adUUID, {
              post_uuid: selectedPost?.uuid,
              order: Number(target.value),
            })
          }
        />
        <label htmlFor={`insert-after-${adUUID}`}>
          <div className="radio-button">After</div>
        </label>
      </div>
    </>
  );
};
