import OpaqueBackground from "../../components/opaque-background";
import Header from "../../components/header";
import SearchAddress from "../../components/search-address";
import InputItem from "../../components/input-item";
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useGlobalContext } from "../../hooks/context/globalContext/globalContext";
import { useAuth } from "../../hooks/context/authContext/authContext";

const LogIn = () => {
  const navigate = useNavigate();

  const { selectedAddress } = useGlobalContext();
  const { login, currentUser } = useAuth();

  const [password, setPassword] = useState("");

  useEffect(() => {
    if (!!currentUser) {
      navigate("/user/dashboard");
    }
  }, [currentUser]);

  const handleLogin = async (event: React.SyntheticEvent) => {
    event.preventDefault();
    try {
      if (selectedAddress && password) {
        const response = await login(selectedAddress?.uuid, password);
        if (!response?.success) {
          navigate("/account-not-found");
        }
      }
    } catch (error) {
      console.error(error);
      navigate("/account-not-found");
    }
  };
  return (
    <div className="log-in">
      <OpaqueBackground>
        <Header />
        <section className="content">
          <p className="label">Welcome Back</p>
          <h1>Log In</h1>
          <form onSubmit={handleLogin}>
            <SearchAddress title={"address"} />
            <InputItem
              onChange={({ target }) => setPassword(target.value)}
              value={password}
              required={true}
              title="Password"
              type="password"
            />
            <span className="input-check">
              <input type="checkbox" />
              <p>Keep me logged in</p>
            </span>
            <button className="primary-button">Log in</button>
          </form>
          {/* <p className="login-paragraph">
            Need an account? <Link to="/create-account">Create one here.</Link>
          </p>
          <p className="login-paragraph">
            Forgot your password? <Link to="/">Reset it here.</Link>
          </p> */}
        </section>
      </OpaqueBackground>
    </div>
  );
};
export default LogIn;
