import { Outlet } from "react-router";
import Announcement from "../../components/card/announcement";
import UserHeader from "../../components/user-header";
import { headerConfigMapper } from "../../components/user-header/header-config";
import { useGlobalContext } from "../../hooks/context/globalContext/globalContext";
import PageContent from "../layout/components/page-content/page-content";
const Announcements = () => {
  const { announcementList, filteredAnnouncements } = useGlobalContext();

  return (
    <div className="announcements page-container">
      <UserHeader {...headerConfigMapper["announcements"]} />
      <PageContent>
        <div className="announcements-container ">
          <section className="announcement-list">
            {filteredAnnouncements.length > 0 ? (
              filteredAnnouncements.map((announcement) =>
                announcement.visibility ? (
                  <Announcement
                    key={announcement.uuid}
                    uuid={announcement.uuid}
                    cardImage={announcement.image_url}
                    title={announcement.title}
                    subtitle={announcement.subtitle}
                    description={announcement.content}
                    createdAt={announcement.created_at}
                    announcement={announcement}
                  />
                ) : null
              )
            ) : (
              <p>No Announcements.</p>
            )}
          </section>
          <p className="no-more">No More Announcements</p>
          <Outlet />
        </div>
      </PageContent>
    </div>
  );
};
export default Announcements;
