// import { faPlusSquare } from "@fortawesome/free-regular-svg-icons";
import {
  faCirclePlus,
  faMagnifyingGlass,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useLocation, useNavigate } from "react-router-dom";
import { HeaderConfig } from "./header-config";
import { useGlobalContext } from "../../hooks/context/globalContext/globalContext";
import { useEffect, useState } from "react";
const UserHeader = (props: HeaderConfig) => {
  let location = useLocation();
  const navigate = useNavigate();
  const {
    setFilteredPosts,
    postList,
    announcementList,
    setFilteredAnnouncements,
    userList,
    setFilteredUsers,
    adList,
    setFilteredAds,
  } = useGlobalContext();

  const itemListMapper = {
    bulletins: [postList, setFilteredPosts],
    announcements: [announcementList, setFilteredAnnouncements],
    users: [userList, setFilteredUsers],
    ads: [adList, setFilteredAds],
  };

  const getListConfig = () => {
    return itemListMapper[props.postType as keyof typeof itemListMapper];
  };

  console.log("render");

  useEffect(() => {
    const listConfig = getListConfig();
    if (listConfig) {
      const [allItemsList, setFilteredList] = listConfig;
      // @ts-ignore
      setFilteredList(allItemsList);
    }
    return () => {
      console.log("saliendo");
      const listConfig = getListConfig();
      if (listConfig) {
        const [allItemsList, setFilteredList] = listConfig;
        // @ts-ignore
        setFilteredList(allItemsList);
      }
    };
  }, []);

  const handleInputChange: React.ChangeEventHandler<HTMLInputElement> = ({
    target,
  }) => {
    if (props.postType) {
      const listConfig = getListConfig();

      if (listConfig) {
        const [allItemsList, setFilteredList] = listConfig;
        // @ts-ignore
        setFilteredList(
          target.value.length > 0
            ? // @ts-ignore
              [...allItemsList].filter((item) =>
                props.filterBy
                  ? item[props.filterBy]
                      ?.toLowerCase()
                      .includes(target.value.toLocaleLowerCase())
                  : item.title
                      ?.toLowerCase()
                      .includes(target.value.toLocaleLowerCase())
              )
            : //@ts-ignore
              [...allItemsList]
        );
      }
    }
    return;
  };

  return (
    <div className="user-header">
      <div className="title-action-button-container">
        <h1>{props?.title}</h1>
        {props?.actionButton ? (
          <button
            onClick={() => {
              // resetSelectedItems();
              navigate(props?.actionButton?.path || "/");
            }}
            className={`action-button ${
              location.pathname.includes("action/new-bulletin") ? "active" : ""
            }`}
          >
            <FontAwesomeIcon icon={faCirclePlus} />
            {props.actionButton.text}
          </button>
        ) : null}
      </div>
      {props?.showSearchBar ? (
        <label className="search">
          <input
            placeholder={`Search ${props.postType ?? props?.title}`}
            onChange={handleInputChange}
          />
          <div className="search-icon">
            <FontAwesomeIcon icon={faMagnifyingGlass} />
          </div>
        </label>
      ) : null}
    </div>
  );
};

export default UserHeader;
