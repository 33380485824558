import { PropsWithChildren } from "react";
import { Navigate, Outlet } from "react-router-dom";
type ProtectedRouteProps = {
  isAllowed: boolean;
  redirectTo?: string;
  // children?: ReactNode;
};
const ProtectedRoute = ({
  isAllowed,
  children,
  redirectTo = "/log-in",
}: PropsWithChildren<ProtectedRouteProps>) => {
  if (!isAllowed) {
    return <Navigate to={redirectTo} replace />;
  }
  return children ? <>{children}</> : <Outlet />;
};
export default ProtectedRoute;
