import { useEffect, useState } from "react";
import {
  AdFormValues,
  createAd,
  getAllAds,
  AdResponseType,
  deleteAdService,
  updateAdService,
} from "../../../services/adService";
import { useAuth } from "../authContext/authContext";

export type UseAdReturn = {
  adFormValues: AdFormValues;
  adList: AdResponseType[];
  filteredAds: AdResponseType[];
  selectedAd: AdResponseType | undefined;
  setAdList: React.Dispatch<React.SetStateAction<AdResponseType[]>>;
  setFilteredAds: React.Dispatch<React.SetStateAction<AdResponseType[]>>;
  setSelectedAd: React.Dispatch<
    React.SetStateAction<AdResponseType | undefined>
  >;
  setAdFormValues: React.Dispatch<React.SetStateAction<AdFormValues>>;
  submitAd: () => Promise<unknown>;
  updateAd: (uuid: string, data?: Partial<AdResponseType>) => Promise<unknown>;
  deleteAd: (uuid: string) => Promise<unknown>;
};
const initialFormState: AdFormValues = {
  title: "",
  ad_url: "",
  image_url: "",
  visibility: true,
};
export const useAd = (): UseAdReturn => {
  const { currentUser } = useAuth();

  const [adFormValues, setAdFormValues] =
    useState<AdFormValues>(initialFormState);
  const [adList, setAdList] = useState<AdResponseType[]>([]);
  const [selectedAd, setSelectedAd] = useState<AdResponseType>();

  const [filteredAds, setFilteredAds] = useState<AdResponseType[]>([]);

  useEffect(() => {
    fetchAds();
  }, []);

  const fetchAds = async () => {
    const fetchedAds = (await getAllAds()) as AdResponseType[];
    setAdList(fetchedAds);
  };

  useEffect(() => {
    setFilteredAds(adList);
    return () => setFilteredAds(adList);
  }, [adList]);

  const submitAd = async () => {
    try {
      if (adFormValues) {
        const newAd = await createAd({
          ...adFormValues,
          admin_uuid: currentUser!.uuid,
        });
        if (newAd) {
          const newAdList = adList;
          newAdList.unshift(newAd);
          setAdList(newAdList);
        }
      } else {
        return;
      }
    } catch (error) {
      console.error("Ad not created", error);
    }
  };

  const deleteAd = async (uuid: string) => {
    try {
      const newAdList = await deleteAdService(uuid);
      setSelectedAd(undefined);
      if (newAdList) {
        setAdList(newAdList);
      }
      // setBulletinFormValues(initialFormState);
    } catch (error) {
      console.error("Ad not deleted", error);
    }
  };
  const updateAd = async (uuid: string, data?: Partial<AdResponseType>) => {
    try {
      if (adFormValues) {
        const newAdList = data
          ? await updateAdService(uuid, data)
          : await updateAdService(uuid, {
              ...adFormValues,
            });
        if (newAdList) {
          setAdList(newAdList);
        }
        setAdFormValues(initialFormState);
        setSelectedAd(undefined);
      } else {
        return;
      }
    } catch (error) {
      console.error("Ad not updated", error);
    }
  };

  return {
    adFormValues,
    adList,
    selectedAd,
    filteredAds,
    setFilteredAds,
    setAdList,
    submitAd,
    setAdFormValues,
    setSelectedAd,
    deleteAd,
    updateAd,
  };
};
