import { useEffect, useState } from "react";
import { getAddresses } from "../../../services/addressService";

export type Address = {
  uuid: string;
  created_at: Date;
  updated_at: Date;
  address: string;
  city?: string;
  state?: string;
  zip?: string;
  lat?: string;
  lng?: string;
  admin?: null;
  already_used?: boolean;
};
export type UseAddressReturn = {
  addressList: Address[];
  selectedAddress: Address | null;
  setSelectedAddress: React.Dispatch<React.SetStateAction<Address | null>>;
  fetchAddresses: () => Promise<void>;
};
export const useAddress = (): UseAddressReturn => {
  const [addressList, setAddressList] = useState<Address[]>([]);
  const [selectedAddress, setSelectedAddress] = useState<Address | null>(null);

  useEffect(() => {
    fetchAddresses();
  }, []);
  const fetchAddresses = async () => {
    const addressList = (await getAddresses()) as Address[];

    setAddressList(addressList);
    // setFilteredAddresses(addressList);
  };
  return {
    fetchAddresses,
    addressList,
    selectedAddress: selectedAddress,
    setSelectedAddress: setSelectedAddress,
  };
};
