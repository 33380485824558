import { SyntheticEvent, useEffect, useState } from "react";
import { useAuth } from "../../../../hooks/context/authContext/authContext";
import ProfileInput from "./components/profile-input/profile-input";
import { UserFormValues } from "../../../../services/userService";
import { useLocation, useNavigate } from "react-router-dom";
import { useGlobalContext } from "../../../../hooks/context/globalContext/globalContext";
import { initialUserFormState } from "../../../../hooks/context/globalContext/useUser";
import SearchAddress from "../../../search-address/search-address";
import toast from "react-hot-toast";

const CreateUser = () => {
  const navigate = useNavigate();
  const { currentUser, getUserCurrentUser } = useAuth();
  const {
    selectedUser,
    userFormValues,
    setUserFormValues,
    submitUser,
    selectedAddress,
    fetchAddresses,
    updateUser,
    setSelectedAddress
  } = useGlobalContext();
  const location = useLocation();
  const [loading, setLoading] = useState<boolean>(false);
  // const [userFormData, setUserFormData] = useState<Partial<CurrentUserType>>(
  //   {}
  // );
  const isUpdateUser = location.pathname.includes("edit");
  const isAdminForm = location.pathname.includes("/admin/user");
  const user = isAdminForm ? selectedUser : currentUser;
  console.log({ userFormValues });

  useEffect(() => {
    getUserCurrentUser();
  }, []);

  const handleSubmit = async (e: SyntheticEvent) => {
    e.preventDefault();
    setLoading(true);
    // const { confirmPassword, password, ...updateUserData } = userFormValues;

    console.log("entra user");
    if (!isUpdateUser && isAdminForm) {
      console.log("create new user");
      await submitUser();
      toast("User created!");
      await fetchAddresses();
    } else {
      if (user) {
        console.log("update user");
        await updateUser(user?.uuid!);
        toast("User updated!");
        if (isAdminForm) {
        }
      }
    }
    if (isAdminForm) {
      setUserFormValues(initialUserFormState);
      setSelectedAddress(null);
      navigate(-1);
    }
    // await updateUserService(user?.uuid || "", {
    //   ...updateUserData,
    // });
    setLoading(false);
    return;
  };

  const handleInputChange = (
    key: string,
    value: string | boolean | File = ""
  ) => {
    setUserFormValues((prev) => ({
      ...prev,
      [key]: value,
    }));
  };
  console.log(isUpdateUser);

  useEffect(() => {
    let formValues: UserFormValues;
    if (isUpdateUser || !isAdminForm) {
      formValues = {
        address: user?.address || userFormValues.address,
        first_name: user?.first_name || "",
        dog_name: user?.dog_name || "",
        last_name: user?.last_name || "",
        about: user?.about || "",
        primary_email: user?.primary_email || "",
        primary_phone: user?.primary_phone || "",
        profile_picture_url: user?.profile_picture_url || "",
        secondary_email: user?.secondary_email || "",
        secondary_phone: user?.secondary_phone || "",
        nickname: user?.nickname || "",
        dog_photo_url: user?.dog_photo_url || "",
        password: "",
        confirmPassword: "",
        approved: user?.approved ?? true,
      };
    } else {
      formValues = {
        ...initialUserFormState,
        address: selectedAddress,
      };
    }
    setUserFormValues({ ...formValues });
  }, [user, selectedAddress]);

  return (
    <div className="create-user">
      <div className="profile-header">
        <h1>Profile</h1>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="inputs-wrapper">
          {!isUpdateUser && isAdminForm ? (
            <div className="search-address-input">
              <SearchAddress
                title="Address"
                isSmallVersion={true}
                filterNonUsed={true}
              />
            </div>
          ) : (
            <ProfileInput
              type="text"
              title="Property Address"
              value={userFormValues!.address?.address}
              readOnly
            />
          )}
          <ProfileInput
            type="text"
            title="Full Name"
            value={userFormValues!.first_name}
            onChange={({ target }) =>
              handleInputChange("first_name", target.value)
            }
          />
          <ProfileInput
            type="text"
            title="Primary Email"
            value={userFormValues.primary_email}
            onChange={({ target }) =>
              handleInputChange("primary_email", target.value)
            }
          />
          <ProfileInput
            type="text"
            title="Secondary Email"
            value={userFormValues.secondary_email}
            onChange={({ target }) =>
              handleInputChange("secondary_email", target.value)
            }
          />
          <ProfileInput
            type="number"
            title="Primary Phone"
            value={userFormValues.primary_phone}
            onChange={({ target }) =>
              handleInputChange("primary_phone", target.value)
            }
          />
          <ProfileInput
            type="number"
            title="Secondary Phone"
            value={userFormValues.secondary_phone}
            onChange={({ target }) =>
              handleInputChange("secondary_phone", target.value)
            }
          />
          <ProfileInput
            type="text"
            title="Dog's Name"
            value={userFormValues.dog_name}
            onChange={({ target }) =>
              handleInputChange("dog_name", target.value)
            }
          />
          {/* <ProfileInput
            type="file"
            title="Dog's Photo"
            onChange={({ target }) =>
              handleInputChange("image_dog_file", target.files?.[0])
            }
            value={
              userFormValues.image_dog_file
                ? URL.createObjectURL(userFormValues.image_dog_file)
                : userFormValues.dog_photo_url ?? ""
            }
          ></ProfileInput> */}
          <ProfileInput
            type="file"
            title="Profile Image"
            onChange={({ target }) =>
              handleInputChange("image_file", target.files?.[0])
            }
            value={
              userFormValues.image_file
                ? URL.createObjectURL(userFormValues.image_file)
                : userFormValues.profile_picture_url ?? ""
            }
          />
          <ProfileInput
            type="textarea"
            title="More About Me"
            value={userFormValues.about}
            onChange={({ target }) => handleInputChange("about", target.value)}
          />
          {isAdminForm && isUpdateUser ? (
            <ProfileInput
              type="select"
              title="Status"
              options={["Active", "Suspended"]}
              value={userFormValues.approved ? "Active" : "Suspended"}
              onChange={({ target }) =>
                handleInputChange(
                  "approved",
                  target.value === "Active" ? true : false
                )
              }
            />
          ) : null}
        </div>
        <button disabled={loading} className="primary-button">
          Save
        </button>
      </form>
    </div>
  );
};
export default CreateUser;
