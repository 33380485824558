import { makeRequest, ServiceResponse } from "./apiService";

export const getAddresses = async () => {
  // Object.keys(filters).forEach((key) => {
  //   if (!filters[key as keyof typeof filters]) {
  //     delete filters[key as keyof typeof filters];
  //   }
  // });
  return makeRequest("GET", "/address");
};

export const markAddressAsUsed = async (address_uuid: string) => {
  try {
    const response = (await makeRequest("PUT", `/address/${address_uuid}`, {
      already_used: true,
    })) as ServiceResponse;
    return response.payload;
  } catch (error) {
    console.error(error);
  }
};
