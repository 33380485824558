import { useEffect, useState } from "react";
import { useGlobalContext } from "../../../../hooks/context/globalContext/globalContext";
import Table from "../../../table/table";
import "./insert-ad.scss";
const InsertAd = () => {
  const { adList, fetchPosts } = useGlobalContext();
  const [mappedAdList, setMappedAdList] = useState<any[]>([]);
  useEffect(() => {
    setMappedAdList(
      adList.map((item) => {
        return {
          uuid: item.uuid,
          ad: {
            image_url: item.image_url,
            title: item.title,
          },
          insert: { order: item.order, post_uuid: item.post_uuid },
        };
      })
    );
    fetchPosts();
  }, [adList]);
  console.log({ adList });
  console.log({ mappedAdList });

  return (
    <div className="insert-ad">
      <h1>Insert Ad</h1>
      <Table
        columnNames={[
          { columnType: "ad", accessKey: "ad", displayLabel: "Ad" },
          {
            columnType: "insert_ad",
            accessKey: "insert",
            displayLabel: "Insert",
          },
        ]}
        data={mappedAdList}
      />
    </div>
  );
};
export default InsertAd;
