import { useState } from "react";
import FileInput from "../file-input/file-input";
import "./profile-input.scss";
import RadioInput from "../radio-input/radio-input";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";

export type ProfileInputProps = {
  title: string;
  options?: string[];
  showError?: boolean;
  errorMessage?: string;
} & React.InputHTMLAttributes<HTMLInputElement>;
const ProfileInput = ({
  showError = false,
  errorMessage = "Invalid field",
  ...props
}: ProfileInputProps) => {
  const [image, setImage] = useState("");
  const getInputComponent = () => {
    switch (props.type) {
      case "file":
        return <FileInput {...props} value={props.value} />;
      case "radio":
        return <RadioInput {...props} options={props.options || []} />;
      case "textarea":
        return (
          <textarea
            value={props.value}
            onChange={
              props.onChange as
                | React.ChangeEventHandler<HTMLTextAreaElement>
                | undefined
            }
          />
        );
      case "select":
        return (
          <span className="custom-select">
            <select
              id={`${props.title}-select`}
              value={props.value}
              onChange={
                props.onChange as
                  | React.ChangeEventHandler<HTMLSelectElement>
                  | undefined
              }
            >
              {props.options?.map((option) => (
                <option key={option}>{option}</option>
              ))}
            </select>
            <span className="selected-value">{props.value}</span>
            <FontAwesomeIcon icon={faCaretDown} />
          </span>
        );
      default:
        return (
          <input
            {...props}
            value={props.value || ""}
            className={`${props.type}-input`}
          />
        );
    }
  };
  return (
    <div className="profile-input">
      <p className="label">{props.title}</p>
      {getInputComponent()}
      {showError ? <p className="error">{errorMessage} *</p> : null}
    </div>
  );
};
export default ProfileInput;
