const Logo = ({ color = "#FFFFFF" }) => {
  return (
    <div className="logo" style={{ color: color }}>
      <svg
        width="51"
        height="61"
        viewBox="0 0 51 61"
        fill={color}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2.38309 49.6618C1.27001 44.7495 4.34985 39.865 9.2621 38.7519L37.4708 32.36C42.4292 31.2365 47.3595 34.3452 48.483 39.3036V39.3036C49.7784 45.0203 45.4642 50.4141 39.62 50.8661C30.6962 51.5562 23.5345 53.1122 15.4243 56.2368C9.94808 58.3467 3.68 55.3853 2.38309 49.6618V49.6618Z"
          fill={color}
        />
        <path
          d="M10.2914 2.67443C10.3748 1.1974 11.6398 0.0676464 13.1168 0.151055L24.441 0.79054C26.1617 0.887705 27.4778 2.36132 27.3806 4.08196V4.08196C27.2712 6.01858 25.4357 7.38011 23.5375 6.98077C18.8158 5.98739 16.0716 5.70517 14.0762 5.8397C12.1326 5.97073 10.1815 4.61933 10.2914 2.67443V2.67443Z"
          fill={color}
        />
        <rect
          x="1.85547"
          y="15.1095"
          width="29.9994"
          height="13.4919"
          rx="6.74596"
          transform="rotate(7.90475 1.85547 15.1095)"
          fill={color}
        />
        <rect
          x="13.9238"
          y="5.52866"
          width="10.9812"
          height="11.1419"
          rx="5.4906"
          transform="rotate(7.90475 13.9238 5.52866)"
          fill={color}
        />
        <path
          d="M12.7728 35.7607C12.4096 34.3266 13.2778 32.8696 14.7119 32.5064L25.7072 29.7222C27.3779 29.2992 29.0751 30.3106 29.4982 31.9812C29.9743 33.8616 28.6312 35.7107 26.7007 35.8987C21.8983 36.3662 19.1957 36.9195 17.3324 37.646C15.5174 38.3535 13.2509 37.6491 12.7728 35.7607Z"
          fill={color}
        />
      </svg>
      <strong>Hillcrest Estates</strong>
    </div>
  );
};
export default Logo;
