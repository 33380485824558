import { ProfileInputProps } from "../profile-input/profile-input";
import "./radio-input.scss";
// type RadioInputProps = {
//   options: string[];
// };
const genderMapper = {
  m: "Male",
  f: "Female",
};
const RadioInput = ({ options, value, onChange }: ProfileInputProps) => {
  return (
    <div className="radio-input">
      {options?.map((option) => (
        <label htmlFor={`radio-${option}`} key={option}>
          <input
            name={"gender"}
            id={`radio-${option}`}
            type="radio"
            value={option}
            checked={value === option}
            onChange={onChange}
          />
          {genderMapper[option as keyof typeof genderMapper]}
        </label>
      ))}
    </div>
  );
};
export default RadioInput;
