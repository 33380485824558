export type HeaderConfig = {
  title: string;
  actionButton?: { text: string; path: string } | null;
  showSearchBar?: boolean;
  postType?: string;
  filterBy?: string;
};
export const headerConfigMapper: { [key: string]: HeaderConfig } = {
  dashboard: {
    title: "home",
    postType: "bulletins",
    actionButton: {
      text: "new bulletin",
      path: "action/new-bulletin",
    },
    showSearchBar: true,
  },
  announcements: {
    title: "announcements",
    postType: "announcements",
    actionButton: null,
    showSearchBar: true,
  },
  "deed-restrictions": {
    title: "deed restrictions",
    actionButton: null,
    showSearchBar: false,
  },
  about: {
    title: "about",
    actionButton: null,
    showSearchBar: false,
  },
  profile: {
    title: "profile",
    actionButton: null,
    showSearchBar: false,
  },
  admin_announcements: {
    title: "Announcements",
    postType: "announcements",
    actionButton: {
      text: "new announcement",
      path: "action/new-announcement",
    },
    showSearchBar: true,
  },
  admin_ads: {
    title: "Ads",
    postType: "ads",
    actionButton: {
      text: "new ad",
      path: "action/new-ad",
    },
    showSearchBar: true,
  },
  admin_posts: {
    title: "bulletins",
    postType: "bulletins",
    actionButton: null,
    showSearchBar: true,
  },
  admin_users: {
    title: "Users",
    postType: "users",
    actionButton: {
      text: "new user",
      path: "action/new-user",
    },
    showSearchBar: true,
    filterBy: "first_name",
  },
};
