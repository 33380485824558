import { markAddressAsUsed } from "./addressService";

export type ServiceResponse = {
  success: boolean;
  status: number;
  payload: any;
};

// const serverApiUrl = "http://localhost:8080";
const serverApiUrl = "https://hillcresthoa-api.herokuapp.com";

export const API = serverApiUrl;
export const AUTH_API = serverApiUrl + "/auth";

let token: string | null = null;

export const getToken = () => token;
export const setToken = (value: string | null) => (token = value);

export const createAccount = async (
  addressUUID: string,
  password: string
): Promise<ServiceResponse> => {
  const response = await fetch(AUTH_API + "/signup", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ address_uuid: addressUUID, password }),
  });
  const result = await response.json();
  if (response.status >= 200 && response.status < 300) {
    await markAddressAsUsed(result.payload.user.address.uuid);
  }
  return result;
  // return new Promise((resolve, reject) => {
  //   fetch(AUTH_API + "/signup", {
  //     method: "POST",
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //     body: JSON.stringify({ address_uuid: addressUUID, password }),
  //   }).then(
  //     (response) => {
  //       if (response.status >= 200 && response.status < 300) {
  //         response.json().then((result) => {
  //           resolve(result);
  //         });
  //       } else {
  //         reject();
  //       }
  //     },
  //     (error) => {
  //       console.error(error);
  //       reject(error);
  //     }
  //   );
  // });
};

export const makeRequest = async (
  method: string,
  path: string,
  params: any = null,
  isFormData: boolean = false
) => {
  return new Promise((resolve, reject) => {
    const url = API;
    if (method === "GET") {
      let endpoint = url + path;
      if (params) {
        let queryString = "?";
        Object.keys(params).forEach((key) => {
          queryString += `${key}=${params[key]}&`;
        });
        endpoint = (endpoint + queryString).slice(0, -1);
      }
      fetch(endpoint, {
        headers: { Authorization: `Bearer ${getToken()}` },
      }).then((response) => {
        if (response.status === 200) {
          response.json().then((result) => {
            resolve(result);
          });
        } else {
          reject();
        }
      });
    } else {
      const body = isFormData ? params : params ? JSON.stringify(params) : null;
      fetch(url + path, {
        method,
        headers: {
          // "Content-Type": isFormData
          //   ? "multipart/form-data"
          //   : "application/json",
          Authorization: `Bearer ${getToken()}`,
        },
        body,
      }).then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((result) => {
            resolve(result);
          });
        } else {
          reject();
        }
      });
    }
  });
};

// export const login = async (email: string, password: string) => {
//   return new Promise((resolve, reject) => {
//     fetch(AUTH_API + "/login", {
//       method: "POST",
//       headers: {
//         "Content-Type": "application/json",
//       },
//       body: JSON.stringify({ email, password }),
//     }).then(
//       (response) => {
//         if (response.status >= 200 && response.status < 300) {
//           response.json().then((result) => {
//             resolve(result);
//           });
//         } else {
//           reject();
//         }
//       },
//       (error) => {
//         console.error(error);
//         reject(error);
//       }
//     );
//   });
// };
