import "./admin-ads.scss";
import { Outlet, useNavigate } from "react-router";
import UserHeader from "../../components/user-header";
import { headerConfigMapper } from "../../components/user-header/header-config";
import { useGlobalContext } from "../../hooks/context/globalContext/globalContext";
import PageContent from "../layout/components/page-content/page-content";
import Table from "../../components/table/table";
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare, faTrashCan } from "@fortawesome/free-solid-svg-icons";
const AdminAds = () => {
  // const [postList, setPostList] = useState<PostResponseType[]>([]);
  const { adList, filteredAds, selectedAd, setSelectedAd, deleteAd } =
    useGlobalContext();
  const [isDeleteAd, setIsDeleteAd] = useState(false);
  const [mappedAdData, setMappedAdData] = useState<any[]>([]);
  useEffect(() => {
    setMappedAdData(
      filteredAds.map((ad) => ({
        visibility: ad.visibility ?? "",
        image_url: ad.image_url ?? "",
        title: ad.title ?? "",
        ad_url: ad.ad_url ?? "",
        created_at: ad.created_at ?? "",
        uuid: ad.uuid ?? "",
      }))
    );
  }, [filteredAds]);

  const deleteRow = async () => {
    await deleteAd(selectedAd!.uuid);
    setIsDeleteAd(false);
  };
  const navigate = useNavigate();
  return (
    <div className="dashboard page-container">
      <UserHeader {...headerConfigMapper["admin_ads"]} />
      <PageContent>
        {selectedAd ? (
          <div className="ad-selected">
            <p>
              1 Ad Selected{" "}
              <FontAwesomeIcon
                onClick={() => {
                  navigate(`action/edit-ad/${selectedAd?.uuid}`);
                }}
                className="edit"
                icon={faPenToSquare}
              />
            </p>

            {isDeleteAd ? (
              <p
                onClick={() => setIsDeleteAd((prev) => !prev)}
                className="delete"
              >
                Cancel
              </p>
            ) : (
              <p
                onClick={() => setIsDeleteAd((prev) => !prev)}
                className="delete"
              >
                <FontAwesomeIcon icon={faTrashCan} />
                Delete
              </p>
            )}

            {isDeleteAd ? (
              <p onClick={() => deleteRow()} className="delete confirm">
                <FontAwesomeIcon icon={faTrashCan} />
                click here to confirm
              </p>
            ) : null}
          </div>
        ) : null}
        <Table
          selectedRow={selectedAd}
          setSelectedRow={setSelectedAd}
          deleteRow={isDeleteAd}
          columnNames={[
            {
              accessKey: "visibility",
              displayLabel: "Visibility",
              columnType: "visibility",
            },
            {
              accessKey: "image_url",
              displayLabel: "Image",
              columnType: "image",
            },
            { accessKey: "title", displayLabel: "Title" },
            { accessKey: "ad_url", displayLabel: "Link", columnType: "link" },
            {
              accessKey: "created_at",
              displayLabel: "Published",
              columnType: "date",
            },
          ]}
          data={mappedAdData}
        />
      </PageContent>
      <Outlet />
    </div>
  );
};
export default AdminAds;
