import { useEffect } from "react";

export const useClickOutComponent = (
  ref: React.MutableRefObject<null>,
  callback: () => void
) => {
  useEffect(() => {
    function handleClickOut(event: MouseEvent) {
      // click out of the component
      if (
        ref.current &&
        !(ref.current as HTMLElement).contains(event.target as HTMLElement)
      ) {
        callback();
      }
    }
    document.addEventListener("mousedown", handleClickOut);
    return () => {
      document.removeEventListener("mousedown", handleClickOut);
    };
  }, [ref, callback]);
};
