import "./bulletin-details.scss"
import { formatDate } from "../../../utils/dates";
import ProfilePicture from "../../profile-picture/profile-picture";
type BulletinDetailsType = {
  userPhoto?: string | null;
  address?: string;
  createdAt: Date;
};
const BulletinDetails = ({
  userPhoto,
  address,
  createdAt,
}: BulletinDetailsType) => {
  return (
    <div className="bulletin-details">
      <ProfilePicture src={userPhoto} />
      <div className="info">
        {address ? <p>{address}</p> : null}
        <i>Posted: {formatDate(new Date(createdAt))}</i>
      </div>
    </div>
  );
};
export default BulletinDetails;
