export const formatDate = (date: Date = new Date()): string => {
  const options: Intl.DateTimeFormatOptions = {
    year: "2-digit",
    month: "numeric",
    day: "numeric",
  };
  return new Date(date)
    .toLocaleDateString(undefined, options)
    .replaceAll("/", ".");
};
export const formatToInputDate = (date?: string) => {
  return date
    ? new Date(date).toISOString().split("T")[0].replace(/-/g, "-")
    : "";
};
