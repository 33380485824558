import { useEffect, useState } from "react";
import {
  BulletinFormValues,
  createPost,
  deletePostService,
  getAllPosts,
  getPostsFeed,
  PostResponseType,
  PostsFeedResponseType,
  updatePost,
} from "../../../services/postService";
import { useAuth } from "../authContext/authContext";

export type UsePostReturn = {
  bulletinFormValues: BulletinFormValues;
  postList: PostsFeedResponseType[];
  filteredPosts: PostsFeedResponseType[];
  setPostList: React.Dispatch<React.SetStateAction<PostsFeedResponseType[]>>;
  setFilteredPosts: React.Dispatch<
    React.SetStateAction<PostsFeedResponseType[]>
  >;
  setBulletinFormValues: React.Dispatch<
    React.SetStateAction<BulletinFormValues>
  >;
  submitBulletin: () => Promise<unknown>;
  updateBulletin: (uuid: string) => Promise<unknown>;
  setSelectedPost: React.Dispatch<
    React.SetStateAction<PostResponseType | undefined>
  >;
  selectedPost: PostResponseType | undefined;
  deletePost: (uuid: string) => Promise<unknown>;
  fetchPosts: () => Promise<unknown>;
};
const initialFormState: BulletinFormValues = {
  title: "",
  content: "",
  image_url: "",
};
export const usePost = (): UsePostReturn => {
  const { currentUser } = useAuth();
  const [bulletinFormValues, setBulletinFormValues] =
    useState<BulletinFormValues>(initialFormState);
  const [postList, setPostList] = useState<PostsFeedResponseType[]>([]);
  const [selectedPost, setSelectedPost] = useState<PostResponseType>();
  const [filteredPosts, setFilteredPosts] = useState<PostsFeedResponseType[]>(
    []
  );

  useEffect(() => {
    fetchPosts();
  }, []);

  useEffect(() => {
    setFilteredPosts(postList);
    return () => setFilteredPosts(postList);
  }, [postList]);

  const fetchPosts = async () => {
    const fetchedPosts = (await getPostsFeed()) as PostsFeedResponseType[];
    setPostList(fetchedPosts);
  };

  const checkBulletinFormValues = () => {
    if (bulletinFormValues.title && bulletinFormValues.content) {
      return true;
    }
    return false;
  };

  const submitBulletin = async () => {
    try {
      if (checkBulletinFormValues()) {
        const newPost = await createPost({
          ...bulletinFormValues,
          user_uuid: currentUser!.uuid,
        });
        if (newPost) {
          const newPostList = postList;
          newPostList.unshift({ type: "post", ...newPost });
          setPostList(newPostList);
        }
        setBulletinFormValues(initialFormState);
        setSelectedPost(undefined);
      } else {
        return;
      }
    } catch (error) {
      console.error("Post not created", error);
    }
  };

  const deletePost = async (uuid: string) => {
    try {
      const newBulletinList = await deletePostService(uuid);
      setSelectedPost(undefined);
      if (newBulletinList) {
        setPostList(newBulletinList);
      }
      // setBulletinFormValues(initialFormState);
    } catch (error) {
      console.error("Bulletin not deleted", error);
    }
  };

  const updateBulletin = async (uuid: string) => {
    try {
      if (bulletinFormValues) {
        const newPostList = await updatePost(uuid, {
          ...bulletinFormValues,
        });
        if (newPostList) {
          setPostList(newPostList);
        }
        setBulletinFormValues(initialFormState);
        setSelectedPost(undefined);
      } else {
        return;
      }
    } catch (error) {
      console.error("Post not updated", error);
    }
  };
  return {
    bulletinFormValues,
    postList,
    setPostList,
    submitBulletin,
    setBulletinFormValues,
    setSelectedPost,
    updateBulletin,
    selectedPost,
    deletePost,
    fetchPosts,
    filteredPosts,
    setFilteredPosts,
  };
};
