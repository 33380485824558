import "./admin-bulletins.scss";
import { Outlet, useNavigate, useLocation } from "react-router-dom";
import UserHeader from "../../components/user-header";
import { headerConfigMapper } from "../../components/user-header/header-config";
import { useGlobalContext } from "../../hooks/context/globalContext/globalContext";
import PageContent from "../layout/components/page-content/page-content";
import Table from "../../components/table/table";
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDollar,
  faPenToSquare,
  faTrashCan,
} from "@fortawesome/free-solid-svg-icons";
const AdminPosts = () => {
  // const [postList, setPostList] = useState<PostResponseType[]>([]);
  const { postList, filteredPosts, selectedPost, setSelectedPost, deletePost } =
    useGlobalContext();
  const [isDeletePost, setIsDeletePost] = useState(false);

  // TODO: map the response in the API
  const [postsMapped, setPostsMapped] = useState<any[]>([]);
  useEffect(() => {
    setPostsMapped(
      filteredPosts.map((item) => ({
        visibility: item.visibility ?? "",
        image_url: item.image_url ?? "",
        title: item.title ?? "",
        content: item.content ?? "",
        user: item.user?.address?.address ?? "",
        created_at: item.created_at ?? "",
        uuid: item.uuid ?? "",
        type: item.type ?? "",
      }))
    );
  }, [filteredPosts]);
  console.log({ postsMapped });

  const deleteRow = async () => {
    await deletePost(selectedPost!.uuid);
    setIsDeletePost(false);
  };
  const navigate = useNavigate();
  const location = useLocation();
  const isInsertAd = location.pathname.includes("insert-ad");
  return (
    <div className="dashboard page-container">
      <UserHeader {...headerConfigMapper["admin_posts"]} />
      <PageContent>
        {selectedPost ? (
          <div className="post-selected">
            <p>
              1 Bulletin Selected{" "}
              <FontAwesomeIcon
                onClick={() => {
                  navigate(`action/edit-post/${selectedPost?.uuid}`, {
                    state: { navigationCounter: 1 },
                  });
                }}
                className="edit"
                icon={faPenToSquare}
              />
            </p>
            <p
              onClick={() => navigate("action/insert-ad")}
              className={`insert-ad ${isInsertAd ? "active" : ""}`}
            >
              <FontAwesomeIcon icon={faDollar} />
              insert ad
            </p>
            {isDeletePost ? (
              <p
                onClick={() => setIsDeletePost((prev) => !prev)}
                className="delete"
              >
                Cancel
              </p>
            ) : (
              <p
                onClick={() => setIsDeletePost((prev) => !prev)}
                className="delete"
              >
                <FontAwesomeIcon icon={faTrashCan} />
                Delete
              </p>
            )}

            {isDeletePost ? (
              <p onClick={() => deleteRow()} className="delete confirm">
                <FontAwesomeIcon icon={faTrashCan} />
                click here to confirm
              </p>
            ) : null}
          </div>
        ) : null}
        <div className="table-container">
          <Table
            selectedRow={selectedPost}
            setSelectedRow={setSelectedPost}
            deleteRow={isDeletePost}
            columnNames={[
              {
                accessKey: "visibility",
                displayLabel: "Visibility",
                columnType: "visibility",
              },
              {
                accessKey: "image_url",
                displayLabel: "Image",
                columnType: "image",
              },
              { accessKey: "title", displayLabel: "Title" },
              { accessKey: "content", displayLabel: "Message" },
              { accessKey: "user", displayLabel: "Author" },
              {
                accessKey: "created_at",
                displayLabel: "Published",
                columnType: "date",
              },
            ]}
            data={postsMapped}
          />
        </div>
      </PageContent>
      <Outlet />
    </div>
  );
};
export default AdminPosts;
