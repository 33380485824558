import {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from "react";
// import { useNavigate } from "react-router";
import { makeRequest, setToken } from "../../../services/apiService";
import { apiLogin } from "../../../services/authService";
import { Address } from "../globalContext/useAddress";
import { PostResponseType } from "../../../services/postService";
export enum Roles {
  neighbor = "neigbor",
  admin = "admin",
}
export type CurrentUserType = {
  uuid: string;
  created_at: Date;
  updated_at: Date;
  password: string | null;
  role: Roles;
  first_name: string;
  last_name: string;
  address_uuid: string;
  primary_phone: string;
  secondary_phone: string;
  primary_email: string;
  secondary_email: string;
  nickname: string;
  about: string;
  dog_name: string;
  dog_photo_url: string;
  verified: boolean;
  approved: boolean;
  profile_picture_url: string | null;
  address: Address | null;
  posts: PostResponseType[];
};

export const authContext = createContext<ReturnType<typeof useAuthContext>>(
  {} as ReturnType<typeof useAuthContext>
);
export const useAuth = () => useContext(authContext);

export const AuthContext = (props: PropsWithChildren<{}>) => (
  <authContext.Provider value={useAuthContext()}>
    {props.children}
  </authContext.Provider>
);

const useAuthContext = () => {
  const [currentUser, setCurrentUser] = useState<CurrentUserType | null>(null);
  const [loginError, setLoginError] = useState<string | null>(null);

  useEffect(() => {
    getUserCurrentUser();
  }, []);

  const getUserCurrentUser = async () => {
    const token = window.localStorage.getItem("token");

    if (!!token) {
      setToken(token);
      const user = await makeRequest("GET", "/user", { token });
      if (user) {
        setCurrentUser(user as CurrentUserType);
      } else {
        setCurrentUser(null);
        setToken(null);
        window.localStorage.removeItem("token");
      }
    }
  };

  const login = async (addressUUID: string, password: string) => {
    try {
      // check localStorage first;
      let result = await apiLogin(addressUUID, password);
      if (result.success) {
        // let bearer = jwtDecode(result.payload.token) as CurrentUserType;

        setToken(result.payload.token);
        setCurrentUser(result.payload.user);
        localStorage.setItem("token", result.payload.token);
        setLoginError(null);
      }
      return result;
    } catch (error) {
      localStorage.removeItem("token");
      setLoginError("Invalid email/password");
      console.error(error);
    }
  };

  const logout = () => {
    // navigate("/");
    setCurrentUser(null);
    localStorage.removeItem("ul-pto-user");
    return;
  };

  return {
    login,
    logout,
    currentUser,
    setCurrentUser,
    loginError,
    getUserCurrentUser,
  };
};
