import { convertObjectToFormData } from "../utils/api.utils";
import { makeRequest, ServiceResponse } from "./apiService";
export type AdFormValues = {
  title: string;
  image_url: string;
  ad_url: string;
  visibility?: boolean;
  image_file?: Blob;
};
export type CreateAdDto = {
  admin_uuid: string;
} & AdFormValues;
export type AdResponseType = {
  uuid: string;
  created_at: Date;
  updated_at: Date;
  title: string;
  ad_url: string;
  image_url?: string;
  visibility: boolean;
  order?: number;
  post_uuid?: string;
};
export const getAllAds = async () => {
  return await makeRequest("GET", "/ads");
};

export const createAd = async (ad: CreateAdDto) => {
  try {
    const formData = convertObjectToFormData(ad);
    const response = (await makeRequest(
      "POST",
      "/ads",
      formData,
      true
    )) as ServiceResponse;
    return response.payload;
  } catch (error) {
    console.error(error);
  }
};

export const deleteAdService = async (ad_uuid: string) => {
  try {
    const response = (await makeRequest(
      "DELETE",
      `/ads/${ad_uuid}`
    )) as ServiceResponse;
    return response.payload;
  } catch (error) {
    console.error(error);
  }
};

export const updateAdService = async (
  ad_uuid: string,
  data: Partial<AdFormValues>
) => {
  try {
    const formData = convertObjectToFormData(data);
    const response = (await makeRequest(
      "PUT",
      `/ads/${ad_uuid}`,
      formData,
      true
    )) as ServiceResponse;
    return response.payload;
  } catch (error) {
    console.error(error);
  }
};
