import { Outlet } from "react-router";
import Bulletin from "../../components/card/bulletin";
import UserHeader from "../../components/user-header";
import { headerConfigMapper } from "../../components/user-header/header-config";
import { useGlobalContext } from "../../hooks/context/globalContext/globalContext";
import PageContent from "../layout/components/page-content/page-content";
import { PostResponseType } from "../../services/postService";
import Advertisement from "../../components/card/advertisement";
import { AdResponseType } from "../../services/adService";
const Dashboard = () => {
  // const [postList, setPostList] = useState<PostResponseType[]>([]);
  const { postList, filteredPosts } = useGlobalContext();

  return (
    <div className="dashboard page-container">
      <UserHeader {...headerConfigMapper["dashboard"]} />
      <PageContent>
        <div className={`dashboard-content`}>
          <h2 className="section-title">Bulletins</h2>
          <section className="post-list">
            {postList.length > 0 ? (
              filteredPosts.map((post) =>
                post.visibility ? (
                  post.type === "post" ? (
                    <Bulletin
                      key={post.uuid}
                      uuid={post.uuid!}
                      userPhoto={post.user!.profile_picture_url}
                      cardImage={post.image_url}
                      title={post.title!}
                      description={post.content!}
                      address={post.user!.address?.address}
                      createdAt={post.created_at!}
                      bulletin={post as PostResponseType}
                    />
                  ) : (
                    <Advertisement
                      key={post.uuid}
                      uuid={post.uuid!}
                      createdAt={post.created_at!}
                      title={post.title!}
                      cardImage={post.image_url!}
                      ad={post as AdResponseType}
                    />
                  )
                ) : null
              )
            ) : (
              <p>No Bulletins.</p>
            )}
          </section>
          <p className="no-more">No More Bulletins</p>
          <Outlet />
        </div>
      </PageContent>
    </div>
  );
};
export default Dashboard;
