import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useGlobalContext } from "../../hooks/context/globalContext/globalContext";
import { Address } from "../../hooks/context/globalContext/useAddress";

const SearchAddress = ({
  title,
  isSmallVersion = false,
  filterNonUsed = false,
}: {
  title: string;
  isSmallVersion?: boolean;
  filterNonUsed?: boolean;
}) => {
  const navigate = useNavigate();

  const { addressList, setSelectedAddress, selectedAddress } =
    useGlobalContext();

  const [searchOpen, setSearchOpen] = useState(false);
  const [filteredAddresses, setFilteredAddresses] = useState<Address[]>([]);
  const [inputValue, setInputValue] = useState("");

  const ref = useRef(null);

  useEffect(() => {
    function handleClickOut(event: MouseEvent) {
      // click out of the component
      if (
        ref.current &&
        !(ref.current as HTMLElement).contains(event.target as HTMLElement)
      ) {
        setSearchOpen(false);
      }
    }
    document.addEventListener("mousedown", handleClickOut);
    return () => {
      document.removeEventListener("mousedown", handleClickOut);
    };
  }, [ref]);

  useEffect(() => {
    if (filterNonUsed) {
      setFilteredAddresses(
        [...addressList].filter((address) => !address.already_used)
      );
    } else {
      setFilteredAddresses(
        [...addressList].filter((address) => address.already_used)
      );
    }
  }, [addressList]);

  useEffect(() => {
    // if (selectedAddress) {
    // navigate("/log-in");
    setInputValue(selectedAddress?.address ?? "");
    // }
  }, [selectedAddress]);

  const filterAddresses = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    setFilteredAddresses(
      [...addressList].filter((address) =>
        filterNonUsed
          ? address.address
              .replace(/\s/g, "")
              .toLowerCase()
              .includes(target.value.replace(/\s/g, "").toLowerCase()) &&
            !address.already_used
          : address.address
              .replace(/\s/g, "")
              .toLowerCase()
              .includes(target.value.replace(/\s/g, "").toLowerCase()) &&
            address.already_used
      )
    );
  };
  const handleUseAddress = (address: Address) => {
    setSelectedAddress(address);
    setSearchOpen(false);
    // if (location.pathname === "/") {
    if (address?.already_used) {
      navigate("/log-in");
    } else {
      // navigate("/create-account");
    }
    // }
  };

  return (
    <div className="search-address">
      <label htmlFor="search">{title}</label>
      <div ref={ref} className="searchbar-addresses">
        <label
          className={`search-bar ${searchOpen && "open"}`}
          htmlFor="input-address"
        >
          <input
            id="input-address"
            name="input-address"
            type="text"
            placeholder="6405 Belmead Drive"
            value={inputValue}
            required
            onClick={() => {
              setSearchOpen(true);
            }}
            onChange={(e) => {
              setInputValue(e.target.value);
              filterAddresses(e);
            }}
          />
          {!isSmallVersion ? (
            <span className="circle">
              <FontAwesomeIcon icon={faMagnifyingGlass} />
            </span>
          ) : null}
        </label>
        {searchOpen && (
          <div
            className="addresses"
            // style={{ display: searchOpen ? "block" : "none" }}
          >
            <ul>
              {filteredAddresses.length > 0 ? (
                filteredAddresses.map((address) => (
                  <li
                    key={address.uuid}
                    onClick={() => handleUseAddress(address)}
                  >
                    <p>{address.address}</p>
                    {!isSmallVersion ? <strong>Use address</strong> : null}
                  </li>
                ))
              ) : (
                <p>No addresses</p>
              )}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};
export default SearchAddress;
