import Header from "../../components/header";
import { useNavigate } from "react-router-dom";

const AccountNotFound = () => {
  const navigate = useNavigate();
  const handleClose = () => {
    navigate("/");
  };
  return (
    <div className="account-not-found">
      <Header />
      <section className="content-container">
        <div className="content">
          <h2>account not found</h2>
          <p>
            It appears your account has been suspended. If you believe this was
            done by mistake, please reach out to{" "}
            <strong>info@hillcrestestates.com</strong> to begin the process of
            reinstating your account.
          </p>
          <button onClick={handleClose} className="secondary-button">
            Close Window
          </button>
        </div>
      </section>
    </div>
  );
};
export default AccountNotFound;
