import { ChangeEvent, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useGlobalContext } from "../../../../hooks/context/globalContext/globalContext";
import { AnnouncementFormValues } from "../../../../services/announcementService";
import InputItem from "../../../input-item";
import ProfileInput from "../create-user/components/profile-input/profile-input";
import FileInput from "../create-user/components/file-input/file-input";

const CreateAnnouncement = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const {
    submitAnnouncement,
    announcementFormValues,
    setAnnouncementFormValues,
    selectedAnnouncement,
    updateAnnouncement,
  } = useGlobalContext();
  const [loading, setLoading] = useState<boolean>(false);
  const isUpdateAnnouncement = location.pathname.includes("edit");
  console.log({ announcementFormValues, selectedAnnouncement });

  useEffect(() => {
    if (isUpdateAnnouncement) {
      if (selectedAnnouncement) {
        setAnnouncementFormValues({
          title: selectedAnnouncement?.title || "",
          subtitle: selectedAnnouncement?.subtitle || "",
          content: selectedAnnouncement?.content || "",
          image_url: selectedAnnouncement?.image_url,
          visibility: selectedAnnouncement?.visibility,
        });
      }
    } else {
      setAnnouncementFormValues({
        title: "",
        subtitle: "",
        content: "",
        image_url: "",
      });
    }
  }, [selectedAnnouncement, isUpdateAnnouncement, setAnnouncementFormValues]);

  const onFormSubmit = async (e: React.SyntheticEvent) => {
    try {
      e.preventDefault();
      setLoading(true);
      if (isUpdateAnnouncement && selectedAnnouncement) {
        await updateAnnouncement(selectedAnnouncement?.uuid);
        navigate(-1);
      } else {
        await submitAnnouncement();
        navigate(-1);
      }
      setLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="create-announcement">
      <h1>{isUpdateAnnouncement ? "Edit Announcement" : "New Announcement"}</h1>
      <form onSubmit={onFormSubmit} className="bulletin-form">
        <InputItem
          title="Announcement Title"
          onChange={({ target }) =>
            setAnnouncementFormValues(
              (prev) =>
                ({ ...prev, title: target.value } as AnnouncementFormValues)
            )
          }
          value={announcementFormValues?.title}
          required={true}
          placeholder={"Announcement Title"}
        />
        <InputItem
          title="Announcement Subtitle"
          onChange={({ target }) =>
            setAnnouncementFormValues(
              (prev) =>
                ({ ...prev, subtitle: target.value } as AnnouncementFormValues)
            )
          }
          value={announcementFormValues?.subtitle}
          required={false}
          placeholder={"Announcement Subtitle"}
        />
        <div key={"input-bulletin-message"} className="input-item">
          <label htmlFor="bulletin-message">Announcement Message</label>
          <textarea
            className="bulletin-message"
            name="bulletin-message"
            onChange={({ target }) =>
              setAnnouncementFormValues(
                (prev) =>
                  ({ ...prev, content: target.value } as AnnouncementFormValues)
              )
            }
            value={announcementFormValues?.content}
            required={true}
            placeholder={"Announcement Message"}
          ></textarea>
        </div>
        <div key={"input-bulletin-image"} className="input-item">
          <label htmlFor="bulletin-message">
            Announcement Image <i style={{ fontWeight: "300" }}>(optional)</i>
          </label>
          <FileInput
            title="Ad Image"
            onChange={({ target }) =>
              setAnnouncementFormValues(
                (prev) =>
                  ({
                    ...prev,
                    image_file: target.files?.[0],
                  } as AnnouncementFormValues)
              )
            }
            value={
              announcementFormValues.image_file
                ? URL.createObjectURL(announcementFormValues.image_file)
                : announcementFormValues.image_url ?? ""
            }
          />
        </div>
        {isUpdateAnnouncement ? (
          <div className="input-item">
            <ProfileInput
              type="select"
              title="Status"
              options={["Visible", "Not Visible"]}
              value={
                announcementFormValues?.visibility ? "Visible" : "Not Visible"
              }
              onChange={({ target }) =>
                setAnnouncementFormValues(
                  (prev) =>
                    ({
                      ...prev,
                      visibility: target.value === "Visible",
                    } as AnnouncementFormValues)
                )
              }
            />
          </div>
        ) : null}
        <button disabled={loading} className="primary-button" type="submit">
          {isUpdateAnnouncement ? "update" : "publish"}
        </button>
      </form>
    </div>
  );
};
export default CreateAnnouncement;
