import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDoorOpen } from "@fortawesome/free-solid-svg-icons";
import { NavLink } from "react-router-dom";
import { Roles, useAuth } from "../../hooks/context/authContext/authContext";
import Logo from "../logo";
import ProfilePicture from "../profile-picture";

const Sidebar = () => {
  const { currentUser, setCurrentUser } = useAuth();

  return (
    <>
      <div className="sidebar-container">
        <Logo color="black" />
        <div className="sidebar-actions">
          <div className="nav-links">
            <nav>
              <ul>
                <li>
                  <NavLink className="sidebar-item" to={"dashboard"}>
                    Home
                  </NavLink>
                </li>
                <li>
                  <NavLink className="sidebar-item" to={"announcements"}>
                    Announcements
                  </NavLink>
                </li>
                <li>
                  <NavLink className="sidebar-item" to={"deed-restrictions"}>
                    Deed Restrictions
                  </NavLink>
                </li>
                <li>
                  <NavLink className="sidebar-item" to={"about"}>
                    About
                  </NavLink>
                </li>
                <li>
                  <NavLink className="sidebar-item" to={"profile"}>
                    {/* <figure className="profile-picture">
                    <FontAwesomeIcon icon={faUser} />
                  </figure> */}
                    <ProfilePicture src={currentUser?.profile_picture_url} />
                    Profile
                  </NavLink>
                </li>
              </ul>
            </nav>
            {currentUser?.role === Roles.admin ? (
              <section className="admin-section">
                <h1>Admin</h1>
                <nav>
                  <ul>
                    <li>
                      <NavLink className="sidebar-item" to={"admin/bulletin"}>
                        Bulletins
                      </NavLink>
                    </li>
                    <li>
                      <NavLink className="sidebar-item" to={"admin/ad"}>
                        Ads
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        className="sidebar-item"
                        to={"admin/announcement"}
                      >
                        Announcements
                      </NavLink>
                    </li>
                    <li>
                      <NavLink className="sidebar-item" to={"admin/user"}>
                        Users
                      </NavLink>
                    </li>
                  </ul>
                </nav>
              </section>
            ) : null}
          </div>

          <button
            className="log-out sidebar-item"
            onClick={() => {
              setCurrentUser(null);
              window.localStorage.removeItem("token");
            }}
          >
            <FontAwesomeIcon icon={faDoorOpen} />
            Log Out
          </button>
        </div>
      </div>
    </>
  );
};
export default Sidebar;
