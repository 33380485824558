import { faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ProfilePicture = ({ src = null }: { src?: string | null }) => {
  return (
    <figure className="profile-picture" style={{ padding: src ? "0" : "10px" }}>
      {src ? (
        <img src={src} alt="user image" />
      ) : (
        <FontAwesomeIcon icon={faUser} />
      )}
    </figure>
  );
};
export default ProfilePicture;
