export const headers = [
  { label: "Approved", key: "approved" },
  { label: "Address", key: "address.address" },
  { label: "Full Name", key: "first_name" },
  { label: "Primary Email", key: "primary_email" },
  { label: "Secondary Email", key: "secondary_email" },
  { label: "Primary Phone", key: "primary_phone" },
  { label: "Secondary Phone", key: "secondary_phone" },
  { label: "Dog's Name", key: "secondary_phone" },
  { label: "Created Date", key: "created_at" },
];
