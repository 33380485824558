// import React from "react";
// import logo from "./logo.svg";
import "./assets/scss/style.scss";
import "./App.css";
import Home from "./pages/home";
import CreateAccount from "./pages/create-account";
import LogIn from "./pages/log-in";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import AccountNotFound from "./pages/account-not-found";
import Layout from "./pages/layout";

function App() {
  return (
    <BrowserRouter>
      <div className="App">
        <Routes>
          <Route index element={<Home />} />
          <Route path="/create-account" element={<CreateAccount />} />
          <Route path="/log-in" element={<LogIn />} />
          <Route path="/account-not-found" element={<AccountNotFound />} />
          <Route path="/user/*" element={<Layout />} />

          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
