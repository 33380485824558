import { ChangeEvent, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useGlobalContext } from "../../../../hooks/context/globalContext/globalContext";
import { BulletinFormValues } from "../../../../services/postService";
import InputItem from "../../../input-item";
import ProfileInput from "../create-user/components/profile-input/profile-input";
import FileInput from "../create-user/components/file-input/file-input";

const CreateBulletin = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const navigationCounter = location.state?.navigationCounter;
  const {
    submitBulletin,
    bulletinFormValues,
    setBulletinFormValues,
    selectedPost,
    updateBulletin,
  } = useGlobalContext();
  const [loading, setLoading] = useState<boolean>(false);
  const isUpdateBulletin = location.pathname.includes("edit");
  const isAdminForm = location.pathname.includes("/admin/bulletin");

  useEffect(() => {
    if (isUpdateBulletin) {
      if (selectedPost) {
        setBulletinFormValues({
          title: selectedPost?.title || "",
          content: selectedPost?.content || "",
          image_url: selectedPost?.image_url,
          visibility: selectedPost?.visibility,
        });
      }
    } else {
      setBulletinFormValues({
        title: "",
        content: "",
        image_url: "",
      });
    }
  }, [selectedPost, isUpdateBulletin, setBulletinFormValues]);

  const onFormSubmit = async (e: React.SyntheticEvent) => {
    try {
      setLoading(true)
      e.preventDefault();
      if (isUpdateBulletin && selectedPost) {
        await updateBulletin(selectedPost?.uuid);
        navigationCounter ? navigate(-1 * navigationCounter) : navigate(-2);
      } else {
        await submitBulletin();
        navigate(-1);
      }
      setLoading(false)
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="create-bulletin">
      <h1>
        {isUpdateBulletin ? "Edit Your Bulletin" : "Create A New Bulletin"}
      </h1>
      <form onSubmit={onFormSubmit} className="bulletin-form">
        <InputItem
          title="Bulletin Title"
          onChange={({ target }) =>
            setBulletinFormValues(
              (prev) => ({ ...prev, title: target.value } as BulletinFormValues)
            )
          }
          value={bulletinFormValues?.title}
          required={true}
          placeholder={"Bulletin Title"}
        />
        <div key={"input-bulletin-message"} className="input-item">
          <label htmlFor="bulletin-message">Bulletin Message</label>
          <textarea
            className="bulletin-message"
            name="bulletin-message"
            onChange={({ target }) =>
              setBulletinFormValues(
                (prev) =>
                  ({ ...prev, content: target.value } as BulletinFormValues)
              )
            }
            value={bulletinFormValues?.content}
            required={true}
            placeholder={"Bulletin Message"}
          ></textarea>
        </div>
        <div key={"input-bulletin-image"} className="input-item">
          <label htmlFor="bulletin-message">
            Bulletin Image <i style={{ fontWeight: "300" }}>(optional)</i>
          </label>
          <FileInput
            title="Bulletin Image"
            onChange={({ target }) =>
              setBulletinFormValues(
                (prev) =>
                  ({
                    ...prev,
                    image_file: target.files?.[0],
                  } as BulletinFormValues)
              )
            }
            value={
              bulletinFormValues.image_file
                ? URL.createObjectURL(bulletinFormValues.image_file)
                : bulletinFormValues.image_url ?? ""
            }
          />

          {isUpdateBulletin && isAdminForm ? (
            <div className="input-item">
              <ProfileInput
                type="select"
                title="Status"
                options={["Visible", "Not Visible"]}
                value={
                  bulletinFormValues?.visibility ? "Visible" : "Not Visible"
                }
                onChange={({ target }) =>
                  setBulletinFormValues(
                    (prev) =>
                      ({
                        ...prev,
                        visibility: target.value === "Visible",
                      } as BulletinFormValues)
                  )
                }
              />
            </div>
          ) : null}
        </div>
        <button
          disabled={loading} className="primary-button"
          type="submit"
        >
          {isUpdateBulletin ? "update" : "publish"}
        </button>
      </form>
    </div>
  );
};
export default CreateBulletin;
