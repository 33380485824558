import { ChangeEvent, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useGlobalContext } from "../../../../hooks/context/globalContext/globalContext";
import { AnnouncementFormValues } from "../../../../services/announcementService";
import InputItem from "../../../input-item";
import { updateUserService } from "../../../../services/userService";
import { useAuth } from "../../../../hooks/context/authContext/authContext";

const ResetPassword = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const {
    submitAnnouncement,
    announcementFormValues,
    setAnnouncementFormValues,
    selectedAnnouncement,
    updateAnnouncement,
  } = useGlobalContext();
  const { currentUser } = useAuth();
  const isUpdateAnnouncement = location.pathname.includes("edit");
  console.log({ announcementFormValues, selectedAnnouncement });

  useEffect(() => {
    if (isUpdateAnnouncement) {
      if (selectedAnnouncement) {
        setAnnouncementFormValues({
          title: selectedAnnouncement?.title || "",
          subtitle: selectedAnnouncement?.subtitle || "",
          content: selectedAnnouncement?.content || "",
          image_url: selectedAnnouncement?.image_url,
        });
      }
    } else {
      setAnnouncementFormValues({
        title: "",
        subtitle: "",
        content: "",
        image_url: "",
      });
    }
  }, [selectedAnnouncement, isUpdateAnnouncement, setAnnouncementFormValues]);

  const onFormSubmit = async (e: React.SyntheticEvent) => {
    try {
      e.preventDefault();
      console.log("RESET PASSWORD");
      if (currentUser) {
        await updateUserService(currentUser.uuid, { password });
      }
      navigate(-1);
    } catch (error) {
      console.error(error);
    }
  };
  console.log(location);

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const checkError = () => {
    if (!!confirmPassword) {
      return confirmPassword === password;
    } else {
      return true;
    }
  };
  return (
    <div className="create-announcement">
      <h1>Reset Password</h1>
      <form onSubmit={onFormSubmit} className="bulletin-form">
        <InputItem
          title="New Password"
          type="password"
          onChange={({ target }) => setPassword(target.value)}
          value={password}
          required={true}
          placeholder="Type your new password"
        />
        <InputItem
          title="Confirm Password"
          type="password"
          onChange={({ target }) => setConfirmPassword(target.value)}
          value={confirmPassword}
          required={true}
          placeholder="Confirm your new password"
          displayError={!checkError()}
          errorMessage="Password should match"
        />
        <button
          disabled={!checkError()}
          className="primary-button"
          type="submit"
        >
          reset
        </button>
      </form>
    </div>
  );
};
export default ResetPassword;
