import "./admin-users.scss";
import { Await, Outlet, useNavigate } from "react-router";
import Bulletin from "../../components/card/bulletin";
import UserHeader from "../../components/user-header";
import { headerConfigMapper } from "../../components/user-header/header-config";
import { useGlobalContext } from "../../hooks/context/globalContext/globalContext";
import PageContent from "../layout/components/page-content/page-content";
import Table from "../../components/table/table";
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFile,
  faPenToSquare,
  faTrashCan,
} from "@fortawesome/free-solid-svg-icons";
import { Toaster } from "react-hot-toast";
import { CSVLink } from "react-csv";
import { headers } from "./csv.config";
const AdminUsers = () => {
  // const [postList, setPostList] = useState<PostResponseType[]>([]);
  const {
    userList,
    filteredUsers,
    selectedUser,
    setSelectedUser,
    deleteUser,
    fetchAddresses,
  } = useGlobalContext();
  const [isDeleteUser, setIsDeleteUser] = useState(false);
  const [mappedUserData, setMappedUserData] = useState<any[]>([]);
  useEffect(() => {
    setMappedUserData(
      filteredUsers.map((user) => ({
        approved: user.approved ?? "",
        profile_picture_url: user.profile_picture_url ?? "",
        address: user.address.address ?? "",
        first_name: user.first_name ?? "",
        primary_email: user.primary_email ?? "",
        secondary_email: user.secondary_email ?? "",
        primary_phone: user.primary_phone ?? "",
        secondary_phone: user.secondary_phone ?? "",
        dog_name: user.dog_name ?? "",
        dog_photo_url: user.dog_photo_url ?? "",
        about: user.about ?? "",
        created_at: user.created_at ?? "",
        uuid: user.uuid,
      }))
    );
  }, [filteredUsers]);
  const deleteRow = async () => {
    await deleteUser(selectedUser!.uuid);
    await fetchAddresses();
    setIsDeleteUser(false);
  };
  const navigate = useNavigate();

  return (
    <div className="dashboard page-container admin-users">
      <UserHeader {...headerConfigMapper["admin_users"]} />
      <PageContent>
        <div className="action-buttons">
          {selectedUser ? (
            <div className="user-selected">
              <p>
                1 User Selected{" "}
                <FontAwesomeIcon
                  onClick={() => {
                    navigate(`action/edit-user/${selectedUser?.uuid}`);
                  }}
                  className="edit"
                  icon={faPenToSquare}
                />
              </p>

              {isDeleteUser ? (
                <p
                  onClick={() => setIsDeleteUser((prev) => !prev)}
                  className="delete"
                >
                  Cancel
                </p>
              ) : (
                <p
                  onClick={() => setIsDeleteUser((prev) => !prev)}
                  className="delete"
                >
                  <FontAwesomeIcon icon={faTrashCan} />
                  Delete
                </p>
              )}

              {isDeleteUser ? (
                <p onClick={() => deleteRow()} className="delete confirm">
                  <FontAwesomeIcon icon={faTrashCan} />
                  click here to confirm
                </p>
              ) : null}
            </div>
          ) : null}
          <CSVLink
            className="download-csv"
            filename="user-list.csv"
            style={{ zIndex: 1 }}
            data={userList}
            headers={headers}
          >
            <FontAwesomeIcon icon={faFile} />
            Download CSV
          </CSVLink>
        </div>

        <Table
          selectedRow={selectedUser}
          setSelectedRow={setSelectedUser}
          deleteRow={isDeleteUser}
          columnNames={[
            {
              accessKey: "approved",
              displayLabel: "Status",
              columnType: "approved",
            },
            {
              accessKey: "profile_picture_url",
              displayLabel: "Photo",
              columnType: "image",
            },
            {
              accessKey: "address",
              displayLabel: "Address",
              // columnType: "address",
            },
            { accessKey: "first_name", displayLabel: "Full Name" },
            { accessKey: "primary_email", displayLabel: "Primary Email" },
            { accessKey: "secondary_email", displayLabel: "Secondary Email" },
            { accessKey: "primary_phone", displayLabel: "Phone" },
            { accessKey: "secondary_phone", displayLabel: "Secondary Phone" },
            {
              accessKey: "dog_name",
              displayLabel: "Dog's Name",
            },
            { accessKey: "dog_photo_url", displayLabel: "Dog's Photo" },
            { accessKey: "about", displayLabel: "About" },
            {
              accessKey: "created_at",
              displayLabel: "Created Date",
              columnType: "date",
            },
          ]}
          data={mappedUserData}
        />
      </PageContent>
      <Outlet />
      <Toaster
        toastOptions={{
          duration: 2000,
          style: {
            backgroundColor: "#598B54",
            color: "white",
            fontWeight: "bold",
          },
        }}
      />
    </div>
  );
};
export default AdminUsers;
