import { useState } from "react";

export type UseActionPageReturn = {
  actionPageOpen: boolean;
  setActionPageOpen: React.Dispatch<React.SetStateAction<boolean>>;
};
export const useActionPage = (): UseActionPageReturn => {
  const [actionPageOpen, setActionPageOpen] = useState(false);

  return {
    actionPageOpen,
    setActionPageOpen,
  };
};
