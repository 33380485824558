export const convertObjectToFormData = (data: Object): FormData => {
  const formData = new FormData();
  Object.keys(data).forEach((key) => {
    const value = data[key as keyof typeof data];
    if (typeof value === "object") {
      formData.append(key, <File>value);
    } else {
      formData.append(key, value.toString());
    }
  });
  return formData;
};
