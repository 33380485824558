import { useNavigate } from "react-router-dom";
import Card from "..";
import { formatDate } from "../../../utils/dates";
import ProfilePicture from "../../profile-picture";
import { CardProps } from "../card";
import { PostResponseType } from "../../../services/postService";
import { useGlobalContext } from "../../../hooks/context/globalContext/globalContext";
import BulletinDetails from "../bulletin-details/bulletin-details";

type BulletinCardType = { bulletin: PostResponseType } & CardProps;
const Bulletin = (props: BulletinCardType) => {
  const navigate = useNavigate();
  const { setSelectedPost } = useGlobalContext();
  const { address, createdAt, description, title, uuid, cardImage, userPhoto } =
    props;
  return (
    <Card>
      <div className="bulletin">
        {cardImage ? (
          <div
            className="bulletin-image"
            style={{ backgroundImage: `url(${cardImage})` }}
          ></div>
        ) : null}
        <div className="bulletin-content">
          <div className="title-description">
            <h2 className="title">{title}</h2>
            {!cardImage ? <p className="description">{description}</p> : null}
          </div>
          {/* <div className="details">
            <ProfilePicture src={userPhoto} />
            <div className="info">
              {address ? <p>{address}</p> : null}
              <i>Posted: {formatDate(new Date(createdAt))}</i>
            </div>
          </div> */}
          <BulletinDetails
            userPhoto={userPhoto}
            address={address}
            createdAt={createdAt}
          />
          <button
            className="details-button"
            onClick={() => {
              setSelectedPost(props.bulletin);
              navigate(`action/bulletin/${uuid}`);
            }}
          >
            details
          </button>
        </div>
      </div>
    </Card>
  );
};
export default Bulletin;
