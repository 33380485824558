import { useNavigate } from "react-router";
import { formatDate } from "../../../utils/dates";
import Card, { CardProps } from "../card";
import { useGlobalContext } from "../../../hooks/context/globalContext/globalContext";
import { AnnouncementResponseType } from "../../../services/announcementService";
type AnnouncementCardType = {
  announcement: AnnouncementResponseType;
} & CardProps;
const Announcement = (props: AnnouncementCardType) => {
  const navigate = useNavigate();
  const { setSelectedAnnouncement } = useGlobalContext();
  return (
    <Card>
      <div
        className={`announcement ${props.cardImage ? "with-image" : ""}`}
        style={
          props.cardImage ? { backgroundImage: `url(${props.cardImage})` } : {}
        }
      >
        <div className="announcement-content">
          <div className="title-description">
            <div className="title-subtitle">
              <h2 className="title">{props.title}</h2>
              {props.cardImage && props.subtitle ? (
                <h3 className="subtitle">{props.subtitle}</h3>
              ) : null}
            </div>
            <div className="info">
              <i>Posted: {formatDate(new Date(props.createdAt))}</i>
            </div>
            {!props.cardImage ? (
              <p className="description">{props.description}</p>
            ) : null}
          </div>
          <button
            className="details-button"
            onClick={() => {
              setSelectedAnnouncement(props.announcement);
              navigate(`action/announcement/${props.uuid}`);
            }}
          >
            details
          </button>
        </div>
      </div>
    </Card>
  );
};
export default Announcement;
