import UserHeader from "../../components/user-header";
import PageContent from "../layout/components/page-content/page-content";
import { config } from "./deed-restrictions.config";

const DeedRestrictions = () => {
  return (
    <div className="deed-restrictions page-container">
      <UserHeader title="Deed Restrictions" />
      <PageContent>
        <div className="deed-restrictions-container">
          <section className="index-info">
            <div className="index">
              <ol className="index-list">
                {config.map(({ title }, index) => (
                  <li key={title} className="index-item">
                    <a href={`#restriction-${index + 1}`}>
                      {index + 1}: {title}
                    </a>
                  </li>
                ))}
              </ol>
            </div>
            <div className="info">
              <h1 className="info-title">What Are Deed Restrictions?</h1>
              <p className="info-paragraph">
                Deed restrictions, also known as covenants, conditions, and
                restrictions (CC&Rs), are legal agreements that govern how
                properties within a community can be used and developed.
              </p>
              <br />
              <p className="info-paragraph">
                These restrictions are typically created by a developer or
                homeowners association (HOA) and are included in the property
                deed when it is sold. They are designed to maintain the overall
                look, feel, and value of the community by setting guidelines for
                things like property use, architectural styles, landscaping, and
                maintenance.
              </p>
              <br />
              <p className="info-paragraph">
                It's important to note that deed restrictions are legally
                binding, and violating them can result in fines or even legal
                action. They can also impact property values, as homes that
                don't comply with these restrictions may be viewed as less
                desirable by potential buyers.
              </p>
              <br />
              <p className="info-paragraph">
                Overall, deed restrictions are intended to help maintain a high
                quality of life within a community, and ensure that everyone is
                able to enjoy their property without interference from their
                neighbors. If you have any questions about the deed restrictions
                in Hillcrest Estates, please don't hesitate to reach out to the
                HOA board for more information.
              </p>
            </div>
          </section>
          <section className="content">
            {config.map(({ title, content }, index) => (
              <div key={title} className="content-item" id={`restriction-${index + 1}`}>
                <h4 className="content-item-title">{title}</h4>
                <p className="content-item-paragraph">{content}</p>
              </div>
            ))}
          </section>
        </div>
      </PageContent>
    </div>
  );
};
export default DeedRestrictions;
