import { useEffect, useState } from "react";
import {
  AnnouncementFormValues,
  createAnnouncement,
  getAllAnnouncements,
  AnnouncementResponseType,
  deleteAnnouncemenetService,
  updateAnnouncementService,
} from "../../../services/announcementService";
import { useAuth } from "../authContext/authContext";

export type UseAnnouncementReturn = {
  announcementFormValues: AnnouncementFormValues;
  announcementList: AnnouncementResponseType[];
  filteredAnnouncements: AnnouncementResponseType[];
  selectedAnnouncement: AnnouncementResponseType | undefined;
  setAnnouncementList: React.Dispatch<
    React.SetStateAction<AnnouncementResponseType[]>
  >;
  setFilteredAnnouncements: React.Dispatch<
    React.SetStateAction<AnnouncementResponseType[]>
  >;
  setSelectedAnnouncement: React.Dispatch<
    React.SetStateAction<AnnouncementResponseType | undefined>
  >;
  setAnnouncementFormValues: React.Dispatch<
    React.SetStateAction<AnnouncementFormValues>
  >;
  submitAnnouncement: () => Promise<unknown>;
  updateAnnouncement: (uuid: string) => Promise<unknown>;
  deleteAnnouncement: (uuid: string) => Promise<unknown>;
};
const initialFormState: AnnouncementFormValues = {
  title: "",
  subtitle: "",
  content: "",
  image_url: "",
  visibility: true,
};
export const useAnnouncement = (): UseAnnouncementReturn => {
  const { currentUser } = useAuth();

  const [announcementFormValues, setAnnouncementFormValues] =
    useState<AnnouncementFormValues>(initialFormState);
  const [announcementList, setAnnouncementList] = useState<
    AnnouncementResponseType[]
  >([]);
  const [filteredAnnouncements, setFilteredAnnouncements] = useState<
    AnnouncementResponseType[]
  >([]);
  const [selectedAnnouncement, setSelectedAnnouncement] =
    useState<AnnouncementResponseType>();

  useEffect(() => {
    fetchAnnouncements();
  }, []);

  useEffect(() => {
    setFilteredAnnouncements(announcementList);
    return () => setFilteredAnnouncements(announcementList);
  }, [announcementList]);

  const fetchAnnouncements = async () => {
    const fetchedAnnouncements =
      (await getAllAnnouncements()) as AnnouncementResponseType[];
    setAnnouncementList(fetchedAnnouncements);
  };

  const submitAnnouncement = async () => {
    try {
      if (announcementFormValues) {
        const newAnnouncement = await createAnnouncement({
          ...announcementFormValues,
          user_uuid: currentUser!.uuid,
        });
        if (newAnnouncement) {
          const newAnnouncementList = announcementList;
          newAnnouncementList.unshift(newAnnouncement);
          setAnnouncementList(newAnnouncementList);
        }
      } else {
        return;
      }
    } catch (error) {
      console.error("Announcement not created", error);
    }
  };

  const deleteAnnouncement = async (uuid: string) => {
    try {
      const newAnnouncementList = await deleteAnnouncemenetService(uuid);
      setSelectedAnnouncement(undefined);
      if (newAnnouncementList) {
        setAnnouncementList(newAnnouncementList);
      }
      // setBulletinFormValues(initialFormState);
    } catch (error) {
      console.error("Announcement not deleted", error);
    }
  };
  const updateAnnouncement = async (uuid: string) => {
    try {
      if (announcementFormValues) {
        const newAnnouncementList = await updateAnnouncementService(uuid, {
          ...announcementFormValues,
        });
        if (newAnnouncementList) {
          setAnnouncementList(newAnnouncementList);
        }
        setAnnouncementFormValues(initialFormState);
        setSelectedAnnouncement(undefined);
      } else {
        return;
      }
    } catch (error) {
      console.error("Announcement not updated", error);
    }
  };

  return {
    announcementFormValues,
    announcementList,
    selectedAnnouncement,
    filteredAnnouncements,
    setFilteredAnnouncements,
    setAnnouncementList,
    submitAnnouncement,
    setAnnouncementFormValues,
    setSelectedAnnouncement,
    deleteAnnouncement,
    updateAnnouncement,
  };
};
