import { PropsWithChildren } from "react";
export type CardProps = {
  title: string;
  subtitle?: string;
  description?: string;
  cardImage?: string | null;
  userPhoto?: string | null;
  uuid: string;
  address?: string;
  createdAt: Date;
};
const Card = ({ children }: PropsWithChildren) => {
  return <div className="card">{children}</div>;
};
export default Card;
