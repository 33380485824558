import { Address } from "../hooks/context/globalContext/useAddress";
import { convertObjectToFormData } from "../utils/api.utils";
import { AUTH_API, makeRequest, ServiceResponse } from "./apiService";
export type AnnouncementFormValues = {
  title: string;
  subtitle?: string;
  content: string;
  image_url?: string | undefined;
  visibility?: boolean;
  image_file?: Blob;
};
export type CreateAnnouncementDto = {
  user_uuid: string;
} & AnnouncementFormValues;
export type AnnouncementResponseType = {
  uuid: string;
  created_at: Date;
  updated_at: Date;
  title: string;
  subtitle?: string;
  content: string;
  image_url?: string;
  visibility: boolean;
};
export const getAllAnnouncements = async () => {
  return await makeRequest("GET", "/announcements");
};

export const createAnnouncement = async (
  announcement: CreateAnnouncementDto
) => {
  try {
    const formData = convertObjectToFormData(announcement);
    const response = (await makeRequest(
      "POST",
      "/announcements",
      formData,
      true
    )) as ServiceResponse;
    return response.payload;
  } catch (error) {
    console.error(error);
  }
};

export const deleteAnnouncemenetService = async (announcement_uuid: string) => {
  try {
    const response = (await makeRequest(
      "DELETE",
      `/announcements/${announcement_uuid}`
    )) as ServiceResponse;
    return response.payload;
  } catch (error) {
    console.error(error);
  }
};

export const updateAnnouncementService = async (
  announcement_uuid: string,
  data: Partial<AnnouncementFormValues>
) => {
  try {
    const formData = convertObjectToFormData(data);
    const response = (await makeRequest(
      "PUT",
      `/announcements/${announcement_uuid}`,
      formData,
      true
    )) as ServiceResponse;
    return response.payload;
  } catch (error) {
    console.error(error);
  }
};
