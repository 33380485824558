import { ChangeEvent, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useGlobalContext } from "../../../../hooks/context/globalContext/globalContext";
import { AdFormValues } from "../../../../services/adService";
import InputItem from "../../../input-item";
import ProfileInput from "../create-user/components/profile-input/profile-input";
import FileInput from "../create-user/components/file-input/file-input";

const CreateAd = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const {
    submitAd,
    adFormValues,
    setAdFormValues,
    selectedAd,
    updateAd,
    fetchPosts,
  } = useGlobalContext();
  const isUpdateAd = location.pathname.includes("edit");
  const [loading, setLoading] = useState<boolean>(false);
  console.log({ adFormValues, selectedAd });

  useEffect(() => {
    if (isUpdateAd) {
      if (selectedAd) {
        setAdFormValues({
          title: selectedAd?.title || "",
          ad_url: selectedAd?.ad_url || "",
          image_url: selectedAd?.image_url || "",
          visibility: selectedAd?.visibility ?? true,
        });
      }
    } else {
      setAdFormValues({
        title: "",
        ad_url: "",
        image_url: "",
      });
    }
  }, [selectedAd, isUpdateAd, setAdFormValues]);

  const onFormSubmit = async (e: React.SyntheticEvent) => {
    try {
      e.preventDefault();
      setLoading(true);
      if (isUpdateAd && selectedAd) {
        await updateAd(selectedAd?.uuid);
        await fetchPosts();
        navigate(-1);
      } else {
        await submitAd();
        navigate(-1);
      }
      setLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="create-ad">
      <h1>{isUpdateAd ? "Edit Ad" : "New Ad"}</h1>
      <form onSubmit={onFormSubmit} className="bulletin-form">
        <InputItem
          title="Ad Title"
          onChange={({ target }) =>
            setAdFormValues(
              (prev) => ({ ...prev, title: target.value } as AdFormValues)
            )
          }
          value={adFormValues?.title}
          required={true}
          placeholder={"Ad Title"}
        />
        <InputItem
          title="Ad Link"
          onChange={({ target }) =>
            setAdFormValues(
              (prev) => ({ ...prev, ad_url: target.value } as AdFormValues)
            )
          }
          value={adFormValues?.ad_url}
          required={true}
          placeholder={"Ad Link"}
        />
        <div key={"input-bulletin-image"} className="input-item">
          <label htmlFor="bulletin-message">Ad Image</label>
          <FileInput
            title="Ad Image"
            onChange={({ target }) =>
              setAdFormValues(
                (prev) =>
                  ({
                    ...prev,
                    image_file: target.files?.[0],
                  } as AdFormValues)
              )
            }
            value={
              adFormValues.image_file
                ? URL.createObjectURL(adFormValues.image_file)
                : adFormValues.image_url ?? ""
            }
          />
        </div>
        {isUpdateAd ? (
          <div className="input-item">
            <ProfileInput
              type="select"
              title="Status"
              options={["Visible", "Not Visible"]}
              value={adFormValues?.visibility ? "Visible" : "Not Visible"}
              onChange={({ target }) =>
                setAdFormValues(
                  (prev) =>
                    ({
                      ...prev,
                      visibility: target.value === "Visible",
                    } as AdFormValues)
                )
              }
            />
          </div>
        ) : null}
        <button disabled={loading} className="primary-button" type="submit">
          {isUpdateAd ? "update" : "publish"}
        </button>
      </form>
    </div>
  );
};
export default CreateAd;
