type InputItemType = {
  title: string;
  type?: React.HTMLInputTypeAttribute;
  placeholder?: string;
  required?: boolean;
  errorMessage?: string;
  displayError?: boolean;
  value?: any;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
};
const InputItem = ({
  title,
  type = "text",
  placeholder,
  required = false,
  errorMessage = "Invalid field",
  displayError = false,
  value,
  onChange,
}: InputItemType) => {
  return (
    <div key={"input-" + title} className="input-item">
      <label htmlFor={title}>{title}</label>
      <label className="input-wrapper" htmlFor={"input-" + title}>
        <input
          id={"input-" + title}
          name={"input" + title}
          type={type}
          value={value || ""}
          placeholder={placeholder}
          onChange={onChange}
          required={required}
        />
      </label>
      {displayError ? <p className="error">{errorMessage} *</p> : null}
    </div>
  );
};
export default InputItem;
