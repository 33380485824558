export type DeedRestriction = { title:string, content:string };
export const config:DeedRestriction[] =[
{title: "Building Setbacks", content:"In order to maintain a harmonious appearance in Hillcrest Estates, all homes must have a minimum setback of 30 feet from the front property line and 10 feet from the side property lines. For example, if your lot is 100 feet wide, your house must be at least 10 feet away from each side property line and 30 feet away from the front property line."},
{title: "Architectural Approval", content: "Before starting any construction or exterior modification projects, homeowners must submit plans to the Architectural Review Committee (ARC) for approval. This ensures that the overall aesthetic of the neighborhood is preserved. For instance, if you want to add a second story or change the color of your house, you must first obtain ARC approval."},
{title: "Landscape Requirements", content: "Homes must have well-maintained and professionally designed front yard landscaping. This includes regular lawn care and maintenance of plantings. For example, if you want to plant a new tree, it should be chosen from an approved list of species and planted in a manner that complements the existing landscape."},
{title: "Fencing Restrictions", content: "Fences must be made of high-quality materials, such as wrought iron, brick, or stone, and should not exceed 6 feet in height. This creates a visually appealing streetscape and allows for clear sight-lines. For example, chain-link or barbed wire fences are not permitted in Hillcrest Estates."},
{title: "Exterior Lighting", content: "Exterior lighting must be designed to be both functional and aesthetically pleasing, without causing excessive light pollution. For example, floodlights should be angled downward and away from neighboring properties, while decorative lighting should be used to highlight architectural features and landscaping."},
{title: "Home Size Requirements", content: "To maintain the upscale nature of Hillcrest Estates, all homes must have a minimum square footage of 3,500 square feet. This ensures that the neighborhood remains cohesive and visually appealing."},
{title: "Prohibited Home Businesses", content: "Home-based businesses that generate excessive noise, traffic, or other disturbances are not permitted in Hillcrest Estates. For example, a small consulting firm with minimal client visits would be allowed, but a car repair business would not be permitted."},
{title: "Noise Regulations", content: "Noise Regulations To preserve the peaceful atmosphere of the neighborhood, excessive noise is prohibited between the hours of 10 p.m. and 7 a.m. Examples of excessive noise include loud music, construction work, or frequent dog barking."},
{title: "Signage Restrictions", content: 'Signage Restrictions Signs displayed on residential properties should be limited to a single "For Sale" or "For Rent" sign, measuring no more than 6 square feet in size. This ensures that the neighborhood remains visually appealing and free of clutter.'},
{title: "Recreational Equipment", content: "Recreational Equipment Recreational equipment, such as basketball hoops and swing sets, must be located in the rear yard and not visible from the street. This helps maintain the upscale appearance of the neighborhood."},
];
