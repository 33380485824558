// import HOAMap from "../../assets/img/hillcrest-estates-map-1.png";
import HOAMap from "../../assets/img/oldHouse2.png";
import PaulPhoto from "../../assets/img/paul-photo.png";
import UserHeader from "../../components/user-header";
import PageContent from "../layout/components/page-content/page-content";

const AboutUs = () => {
  return (
    <div className="about-us page-container">
      <UserHeader title="About Us" />
      <PageContent>
        <div className="about-us-container">
          <section className="about-section">
            <img
              className="about-section-img"
              src={HOAMap}
              alt="hillcrest estates map"
            />
            <div className="about-us-section-content">
              <h4>Our Mission</h4>
              <p>
                We want to create a digital space where genuine moments online
                lead to real connection in the outside world. That's why we're
                excited to announce the launch of this new website, designed to
                be more than just an information hub for Hillcrest Estates.
                <br />
                <br />
                We want our website to be a virtual extension of our community,
                a place where residents can connect with each other, share
                experiences, and build relationships.
              </p>
            </div>
          </section>
          <section className="about-section">
            {/* <div className="about-section-img">

        </div> */}
            <img
              className="about-section-img"
              src={PaulPhoto}
              alt="hillcrest estates map"
            />
            <div className="about-us-section-content">
              <h4>Our Founder</h4>
              <p>
                Paul Magnuson founded this online community in 2023. He saw the
                need for a dedicated space where his neighbors and friends could
                communicate on events and alerts quite literally close to home.
                <br />
                <br />
                {/* Paul is an experienced Investment Analyst and Portfolio Manager
                responsible for Small Cap Value and Mid Cap Value strategies. A
                four-time Lipper Award winner and Nominated Morningstar Domestic
                Stock Fund Manager of the year, he enjoys time with his family
                and meeting new people on his off-days. */}
              </p>
            </div>
          </section>
        </div>
      </PageContent>
    </div>
  );
};
export default AboutUs;
