import { createContext, PropsWithChildren, useContext } from "react";
import { useActionPage, UseActionPageReturn } from "./useActionPage";
import { useAddress, UseAddressReturn } from "./useAddress";
import { useAnnouncement, UseAnnouncementReturn } from "./useAnnouncement";
import { usePost, UsePostReturn } from "./usePost";
import { useAd, UseAdReturn } from "./useAd";
import { useUser, UseUserReturn } from "./useUser";

export const globalContext = createContext<ReturnType<typeof useGlobal>>(
  {} as ReturnType<typeof useGlobal>
);
export const useGlobalContext = () => useContext(globalContext);

export const GlobalContext = (props: PropsWithChildren<{}>) => (
  <globalContext.Provider value={useGlobal()}>
    {props.children}
  </globalContext.Provider>
);
const useGlobal = (): UseAddressReturn &
  UseActionPageReturn &
  UsePostReturn &
  UseAnnouncementReturn &
  UseAdReturn &
  UseUserReturn => {
  const useAddressContext = useAddress();
  const useActionPageContext = useActionPage();
  const usePostContext = usePost();
  const useAnnouncementContext = useAnnouncement();
  const useAdContext = useAd();
  const useUserContext = useUser();
  return {
    ...useAddressContext,
    ...useActionPageContext,
    ...usePostContext,
    ...useAnnouncementContext,
    ...useAdContext,
    ...useUserContext,
  };
};
