import { Address } from "../hooks/context/globalContext/useAddress";
import { convertObjectToFormData } from "../utils/api.utils";
import { AdResponseType } from "./adService";
import { AUTH_API, makeRequest, ServiceResponse } from "./apiService";
export type BulletinFormValues = {
  title: string;
  content: string;
  image_url?: string;
  image_file?: Blob;
  visibility?: boolean;
};
export type CreatePostDto = {
  user_uuid: string;
} & BulletinFormValues;
export type PostResponseType = {
  uuid: string;
  created_at: Date;
  updated_at: Date;
  title: string;
  content: string;
  image_url: string;
  visibility: boolean;
  user: {
    uuid: string;
    profile_picture_url: string | null;
    address: Address | null;
  };
};
export type PostsFeedResponseType = Partial<PostResponseType> &
  Partial<AdResponseType> & { type: string };

export const getAllPosts = async () => {
  return await makeRequest("GET", "/posts");
};

export const getPostsFeed = async () => {
  return await makeRequest("GET", "/posts/feed");
};

export const createPost = async (post: CreatePostDto) => {
  try {
    const formData = convertObjectToFormData(post);
    const response = (await makeRequest(
      "POST",
      "/posts",
      formData,
      true
    )) as ServiceResponse;
    return response.payload;
  } catch (error) {
    console.error(error);
  }
};

export const deletePostService = async (ad_uuid: string) => {
  try {
    const response = (await makeRequest(
      "DELETE",
      `/posts/${ad_uuid}`
    )) as ServiceResponse;
    return response.payload;
  } catch (error) {
    console.error(error);
  }
};

export const updatePost = async (
  post_uuid: string,
  data: Partial<BulletinFormValues>
) => {
  try {
    const formData = convertObjectToFormData(data);
    // data.updated_at = new Date();
    // data.gender =
    //   Gender[data.gender?.toLowerCase() as keyof typeof Gender] || null;
    const response = (await makeRequest(
      "PUT",
      `/posts/${post_uuid}`,
      formData,
      true
    )) as ServiceResponse;
    return response.payload;
  } catch (error) {
    console.error(error);
  }
};
