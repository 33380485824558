import { ProfileInputProps } from "../profile-input/profile-input";
import "./file-input.scss";
import { ChangeEvent, useEffect, useState } from "react";

const FileInput = ({ value, ...props }: ProfileInputProps) => {
  return (
    <div className="file-input">
      <input
        id={`${props.title}-image-input`}
        className="bulletin-image"
        name="bulletin-image"
        type="file"
        accept="image/*"
        // value={imageUrl}
        required={false}
        {...props}
      />

      <label htmlFor={`${props.title}-image-input`} className="select-button">
        Choose A File
      </label>

      {value && (
        <figure className="image-container">
          <img className="image-preview" src={value.toString()} alt="preview" />
        </figure>
      )}
    </div>
  );
};
export default FileInput;
