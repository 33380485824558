import { useEffect } from "react";
import { useGlobalContext } from "../../../../hooks/context/globalContext/globalContext";
import { formatDate } from "../../../../utils/dates";
import BulletinDetails from "../../../card/bulletin-details/bulletin-details";
import ProfilePicture from "../../../profile-picture/profile-picture";

const BulletinDescription = () => {
  const { selectedPost, setSelectedPost } = useGlobalContext();
  useEffect(() => {
    console.log("montaje")
    return () => {
      console.log("Se va a desmontar");
      // setSelectedPost(undefined);
    };
  }, []);
  return selectedPost ? (
    <div className="bulletin-description">
      {selectedPost?.image_url ? (
        <section className="image-section">
          <img src={selectedPost?.image_url} alt="" />
        </section>
      ) : null}
      <section className="content">
        <h1>{selectedPost?.title}</h1>
        <p>{selectedPost?.content}</p>
        <div className="info">
          <BulletinDetails
            createdAt={selectedPost?.created_at}
            address={selectedPost.user.address?.address}
            userPhoto={selectedPost.user.profile_picture_url}
          />
        </div>
      </section>
    </div>
  ) : null;
};
export default BulletinDescription;
