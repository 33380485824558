import { PropsWithChildren, useEffect, useRef, useState } from "react";
import "./page-content.scss";
const PageContent = ({ children }: PropsWithChildren) => {
  const [isScrolled, setIsScrolled] = useState(false);
  const pageRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const container = pageRef.current;
    const handleScroll = () => {
      if (container) {
        const scrollPosition = container.scrollTop;
        setIsScrolled(scrollPosition > 0);
      }
    };

    container?.addEventListener("scroll", handleScroll);
    return () => container?.removeEventListener("scroll", handleScroll);
  }, []);
  return (
    <div ref={pageRef} className="page-content-container">
      <div className={`scrolled ${isScrolled ? "active" : ""}`}></div>
      <div className="page-content">{children}</div>
    </div>
  );
};
export default PageContent;
