import { useEffect, useState } from "react";
import {
  UserFormValues,
  createUser,
  getAllUsers,
  UserResponseType,
  deleteAnnouncemenetService,
  updateUserService,
} from "../../../services/userService";
import { useAuth } from "../authContext/authContext";

export type UseUserReturn = {
  userFormValues: UserFormValues;
  userList: UserResponseType[];
  filteredUsers: UserResponseType[];
  selectedUser: UserResponseType | undefined;
  setUserList: React.Dispatch<React.SetStateAction<UserResponseType[]>>;
  setFilteredUsers: React.Dispatch<React.SetStateAction<UserResponseType[]>>;
  setSelectedUser: React.Dispatch<
    React.SetStateAction<UserResponseType | undefined>
  >;
  setUserFormValues: React.Dispatch<React.SetStateAction<UserFormValues>>;
  submitUser: () => Promise<unknown>;
  updateUser: (uuid: string) => Promise<unknown>;
  deleteUser: (uuid: string) => Promise<unknown>;
};
export const initialUserFormState: UserFormValues = {
  // password: "$2b$10$Um8MM62.ye9p993DyquLRezjQ./9E4PG1OCp.yb4bzAtQl.w1i2B6",
  first_name: "",
  last_name: "",
  address: {
    uuid: "",
    created_at: new Date(),
    updated_at: new Date(),
    address: "",
  },
  primary_phone: "",
  secondary_phone: "",
  primary_email: "",
  secondary_email: "",
  nickname: "",
  about: "",
  dog_name: "",
  dog_photo_url: "",
  profile_picture_url: "",
  password: "",
  confirmPassword: "",
  approved: true,
};
export const useUser = (): UseUserReturn => {
  const { currentUser } = useAuth();

  const [userFormValues, setUserFormValues] =
    useState<UserFormValues>(initialUserFormState);
  const [userList, setUserList] = useState<UserResponseType[]>([]);
  const [selectedUser, setSelectedUser] = useState<UserResponseType>();
  const [filteredUsers, setFilteredUsers] = useState<UserResponseType[]>([]);

  useEffect(() => {
    fetchUsers();
  }, []);

  useEffect(() => {
    setFilteredUsers(userList);
    return () => setFilteredUsers(userList);
  }, [userList]);

  const fetchUsers = async () => {
    const fetchedUsers = (await getAllUsers()) as UserResponseType[];
    setUserList(fetchedUsers);
  };

  const submitUser = async () => {
    try {
      if (userFormValues?.address?.address) {
        const { confirmPassword, ...dataToSend } = userFormValues;
        console.log({ dataToSend });

        dataToSend.password = "sa123456";
        const newUser = await createUser({
          address_uuid: dataToSend?.address?.uuid || "",
          ...dataToSend,
        });
        if (newUser) {
          const newUserList = userList;
          newUserList.unshift(newUser);
          setUserList(newUserList);
        }
      } else {
        return;
      }
    } catch (error) {
      console.error("User not created", error);
    }
  };

  const deleteUser = async (uuid: string) => {
    try {
      const newUserList = await deleteAnnouncemenetService(uuid);
      console.log({ newUserList });

      setSelectedUser(undefined);
      if (newUserList) {
        setUserList(newUserList);
      }
      // setBulletinFormValues(initialFormState);
    } catch (error) {
      console.error("User not deleted", error);
    }
  };
  const updateUser = async (uuid: string) => {
    try {
      if (userFormValues) {
        const { confirmPassword, password, address, ...updateUserData } =
          userFormValues;
        const newUserList = await updateUserService(uuid, {
          ...updateUserData,
        });
        if (newUserList) {
          setUserList(newUserList);
        }
        // setUserFormValues(initialUserFormState);
        setSelectedUser(undefined);
      } else {
        return;
      }
    } catch (error) {
      console.error("User not updated", error);
    }
  };

  return {
    userFormValues,
    userList,
    selectedUser,
    filteredUsers,
    setFilteredUsers,
    setUserList,
    submitUser,
    setUserFormValues,
    setSelectedUser,
    deleteUser,
    updateUser,
  };
};
