import {
  CurrentUserType,
  Roles,
} from "../hooks/context/authContext/authContext";
import { Address } from "../hooks/context/globalContext/useAddress";
import { convertObjectToFormData } from "../utils/api.utils";
import { ServiceResponse, makeRequest } from "./apiService";
import { PostResponseType } from "./postService";
export enum Gender {
  male = "m",
  female = "f",
  other = "o",
}

export type UserFormValues = {
  // password: "$2b$10$Um8MM62.ye9p993DyquLRezjQ./9E4PG1OCp.yb4bzAtQl.w1i2B6",
  first_name: string;
  last_name: string;
  address: Address | null;
  primary_phone: string;
  secondary_phone: string;
  primary_email: string;
  secondary_email: string;
  nickname?: string;
  about: string;
  dog_name: string;
  dog_photo_url: string;
  profile_picture_url: string | null;
  password: string;
  confirmPassword: string;
  approved: boolean;
  image_file?: File;
  image_dog_file?: File;
};
export type CreateUserDto = {
  address_uuid: string;
} & Omit<UserFormValues, "confirmPassword">;
export type UserResponseType = {
  uuid: string;
  created_at: Date;
  updated_at: Date;
  password: string | null;
  role: Roles;
  first_name: string;
  last_name: string;
  address_uuid: string;
  primary_phone: string;
  secondary_phone: string;
  primary_email: string;
  secondary_email: string;
  nickname: string;
  about: string;
  dog_name: string;
  dog_photo_url: string;
  verified: boolean;
  approved: boolean;
  profile_picture_url: string | null;
  address: Address;
  posts: PostResponseType[];
};

export const getAllUsers = async () => {
  return await makeRequest("GET", "/user/all");
};

export const createUser = async (user: CreateUserDto) => {
  try {
    const userFormData = convertObjectToFormData(user);
    const response = (await makeRequest(
      "POST",
      "/user",
      userFormData,
      true
    )) as ServiceResponse;
    return response.payload;
  } catch (error) {
    console.error(error);
  }
};

export const deleteAnnouncemenetService = async (user_uuid: string) => {
  try {
    const response = (await makeRequest(
      "DELETE",
      `/user/${user_uuid}`
    )) as ServiceResponse;
    return response.payload;
  } catch (error) {
    console.error(error);
  }
};

export const updateUserService = async (
  user_uuid: string,
  data: Partial<CurrentUserType>
) => {
  try {
    console.log({ data });
    const formData = convertObjectToFormData(data);
    data.updated_at = new Date();
    // data.gender =
    //   Gender[data.gender?.toLowerCase() as keyof typeof Gender] || null;
    const response = (await makeRequest(
      "PUT",
      `/user/${user_uuid}`,
      formData,
      true
    )) as ServiceResponse;
    return response.payload;
  } catch (error) {
    console.error(error);
  }
};
