import "./announcement-description.scss";
import { useGlobalContext } from "../../../../hooks/context/globalContext/globalContext";

const AnnouncementDescription = () => {
  const { selectedAnnouncement, setSelectedAnnouncement } = useGlobalContext();

  return selectedAnnouncement ? (
    <div className="announcement-description">
      {selectedAnnouncement?.image_url ? (
        <section className="image-section">
          <img src={selectedAnnouncement?.image_url} alt="" />
        </section>
      ) : null}
      <section className="content">
        <div className="titles-section">
          <h1>{selectedAnnouncement?.title}</h1>
          <h2>{selectedAnnouncement?.subtitle}</h2>
        </div>
        <p>{selectedAnnouncement?.content}</p>
      </section>
    </div>
  ) : null;
};
export default AnnouncementDescription;
