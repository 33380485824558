import { useNavigate } from "react-router";
import { useAuth } from "../../hooks/context/authContext/authContext";
import "./user-post-list.scss";
import { useGlobalContext } from "../../hooks/context/globalContext/globalContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPenToSquare,
  faTrashCan,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
import {
  PostResponseType,
  PostsFeedResponseType,
} from "../../services/postService";
const UserPostList = () => {
  const { currentUser } = useAuth();
  const { selectedPost, setSelectedPost, deletePost, postList } =
    useGlobalContext();
  const [isDeletePost, setIsDeletePost] = useState(false);
  const [userPostList, setUserPostList] = useState<PostsFeedResponseType[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();

  useEffect(() => {
    setUserPostList(
      [...postList].filter((post) => post.user?.uuid === currentUser?.uuid)
    );
  }, [postList]);

  const deleteBulletin = async () => {
    setLoading(true);
    await deletePost(selectedPost!.uuid);
    setIsDeletePost(false);
    setLoading(false);
  };
  return (
    <div className="user-post-list">
      {userPostList.map((post) => {
        // post.user = currentUser;
        // post.user.profile_picture_url = currentUser.profile_picture_url;
        // post.user!.uuid = currentUser.uuid;
        console.log({ post });

        return (
          <div key={post.uuid} className="user-post">
            {isDeletePost && selectedPost?.uuid === post.uuid ? (
              <div className="confirm-deletion">
                <h2>Are you sure you want to delete this bulletin?</h2>
                <div className="action-section">
                <button
                    disabled={loading}
                    onClick={() => {
                      deleteBulletin();
                    }}
                    className="delete confirm"
                  >
                    delete
                    <FontAwesomeIcon icon={faTrashCan} />
                  </button>
                  <p
                    onClick={() => setIsDeletePost(false)}
                    className="delete cancel"
                  >
                    {/* <FontAwesomeIcon icon={faXmark} /> */}
                    cancel
                  </p>
                </div>
              </div>
            ) : null}
            {post.image_url ? <img src={post.image_url} /> : null}
            <div className="bulletin-content">
              <h1 className="title">{post.title}</h1>
              <p style={{ WebkitLineClamp: post.image_url ? "2" : "5" }}>
                {post.content}
              </p>
            </div>
            <div className="action-buttons">
              <button
                className="edit"
                onClick={() => {
                  // setNavigationCounter((prev) => prev + 1);
                  setSelectedPost(post as PostResponseType);
                  navigate(`action/edit-bulletin/${selectedPost?.uuid}`);
                }}
              >
                edit
                <FontAwesomeIcon icon={faPenToSquare} />
              </button>
              <div className="delete-section">
                {/* {isDeletePost && selectedPost?.uuid === post.uuid ? (
                  <p
                    onClick={() => setIsDeletePost(false)}
                    className="delete cancel"
                  >
                    <FontAwesomeIcon icon={faXmark} />
                    cancel
                  </p>
                ) : ( */}
                  <p
                    onClick={() => {
                      setSelectedPost(post as PostResponseType);
                      setIsDeletePost(true);
                    }}
                    className="delete"
                  >
                    Delete
                    <FontAwesomeIcon icon={faTrashCan} />
                  </p>
                {/* )} */}

                {/* {isDeletePost && selectedPost?.uuid === post.uuid ? (
                  <button
                    disabled={loading}
                    onClick={() => {
                      deleteBulletin();
                    }}
                    className="delete confirm"
                  >
                    confirm
                    <FontAwesomeIcon icon={faTrashCan} />
                  </button>
                ) : null} */}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default UserPostList;
