import { useEffect, useState } from "react";
import { useGlobalContext } from "../../hooks/context/globalContext/globalContext";
import { formatDate } from "../../utils/dates";
import ProfilePicture from "../profile-picture/profile-picture";
import ColumnTypeAd from "./column-type-components/ad/column-ad";
import { ColumnInsertAd } from "./column-type-components/insert-ad/column-insert-ad";
import "./table.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faCaretUp } from "@fortawesome/free-solid-svg-icons";
type TableColumn = {
  accessKey: string;
  displayLabel: string;
  columnType?: string;
};
type TableProps = {
  data: any[];
  columnNames: TableColumn[];
  selectedRow?: any;
  setSelectedRow?: any;
  deleteRow?: boolean;
};
const Table = ({
  data,
  columnNames,
  selectedRow,
  setSelectedRow,
  deleteRow,
}: TableProps) => {
  const [selectedColumn, setSelectedColumn] = useState<string>("");
  const [order, setOrder] = useState<string>("");
  const [sortedTable, setSortedTable] = useState<any[]>([]);
  console.log({ sortedTable });

  useEffect(() => {
    const sortByColumn = (a: any, b: any) => {
      if (a[selectedColumn] < b[selectedColumn]) {
        return order === "DESC" ? 1 : order === "ASC" ? -1 : 0;
      }
      if (a[selectedColumn] > b[selectedColumn]) {
        return order === "DESC" ? -1 : order === "ASC" ? 1 : 0;
      }
      return 0;
    };
    if (order === "") {
      setSelectedColumn("");
    }
    setSortedTable([...data].sort(sortByColumn));
  }, [data, selectedColumn, order]);

  const getDeleteRow = (uuid: string) => {
    return deleteRow && selectedRow?.uuid === uuid;
  };

  const COLUMNS_NUMBER = columnNames.length;
  const gridTemplateColumns = `${
    setSelectedRow ? "minmax(100px, 250px)" : ""
  } repeat(${COLUMNS_NUMBER}, minmax(100px, 250px))`;

  return (
    <div className="table">
      <div
        className="table-row"
        style={{
          gridTemplateColumns,
        }}
      >
        {setSelectedRow ? (
          <div className="table-column table-header fixed-column"></div>
        ) : null}
        {columnNames?.map((column) => (
          <div
            className={`table-column table-header ${
              column.accessKey === selectedColumn && !!order
                ? "selected-column"
                : ""
            }`}
            key={column.accessKey}
            onClick={() => {
              setSelectedColumn(column.accessKey);
              setOrder((prevOrder) => {
                if (!!selectedColumn && column.accessKey !== selectedColumn) {
                  return "";
                }
                return prevOrder === "DESC"
                  ? "ASC"
                  : prevOrder === "ASC"
                  ? ""
                  : "DESC";
              });
            }}
          >
            {column.displayLabel}
            {column.accessKey === selectedColumn && !!order ? (
              <FontAwesomeIcon
                icon={order === "DESC" ? faCaretDown : faCaretUp}
              />
            ) : null}
          </div>
        ))}
      </div>
      {sortedTable?.map((item) => (
        <div
          className={`table-row ${
            item?.uuid === selectedRow?.uuid ? "active" : ""
          } ${item.type === "ad" ? "ad-row" : ""}`}
          style={{
            backgroundColor: getDeleteRow(item?.uuid)
              ? "rgba(203, 89, 89, 0.2)"
              : "",
            gridTemplateColumns,
          }}
          key={item.uuid}
        >
          {setSelectedRow ? (
            <div
              onClick={() => {
                console.log("click");
                if (item?.uuid === selectedRow?.uuid) {
                  setSelectedRow(null);
                }
              }}
              className="table-column fixed-column"
            >
              {item.type !== "ad" ? (
                <input
                  onChange={() => setSelectedRow(item)}
                  id={`check-${item.uuid}`}
                  name="column"
                  type="radio"
                  checked={item?.uuid === selectedRow?.uuid}
                />
              ) : null}
            </div>
          ) : null}
          {columnNames?.map((column) => (
            <div className="table-column" key={column.accessKey}>
              {column.columnType === "image" ? (
                item[column.accessKey] ? (
                  <ProfilePicture src={item[column.accessKey]} />
                ) : null
              ) : column.columnType === "date" ? (
                formatDate(new Date(item[column.accessKey]))
              ) : column.columnType === "visibility" ? (
                item[column.accessKey] ? (
                  <p>
                    Visible
                    {item.type === "ad" ? <strong> (Ad)</strong> : ""}
                  </p>
                ) : (
                  <p className="not-visible">
                    Not Visible
                    {item.type === "ad" ? <strong> (Ad)</strong> : ""}
                  </p>
                )
              ) : column.columnType === "approved" ? (
                item[column.accessKey] ? (
                  <p>Active</p>
                ) : (
                  <p className="not-visible">Suspended</p>
                )
              ) : column.columnType === "ad" ? (
                <ColumnTypeAd
                  imageUrl={item[column.accessKey]?.image_url}
                  adTitle={item[column.accessKey]?.title}
                />
              ) : column.columnType === "insert_ad" ? (
                <ColumnInsertAd
                  adUUID={item.uuid}
                  postUUID={item[column.accessKey].post_uuid}
                  order={item[column.accessKey].order}
                />
              ) : column.columnType === "address" ? (
                <p>{item[column.accessKey]?.address}</p>
              ) : (
                <p
                  style={{
                    maxWidth:
                      column.columnType === "link" ? "100px" : undefined,
                  }}
                >
                  {item[column.accessKey]}
                </p>
              )}
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};
export default Table;
