import Card, { CardProps } from "../card";
import { AdResponseType } from "../../../services/adService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUpRightFromSquare } from "@fortawesome/free-solid-svg-icons";
type AdCardType = {
  ad: AdResponseType;
} & CardProps;
const Advertisement = (props: AdCardType) => {
  return (
    <Card>
      <div
        className={`advertisement ${props.cardImage ? "with-image" : ""}`}
        style={
          props.cardImage ? { backgroundImage: `url(${props.cardImage})` } : {}
        }
      >
        <div className="announcement-content">
          <div className="title-description">
            <div className="ad">
              <p>Advertisement</p>
            </div>
            <div className="info">
              <h2 className="title">{props.title}</h2>
            </div>
          </div>
        </div>
        <a className="visit-button" href={props.ad.ad_url} target="_blank">
          Visit
          <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
        </a>
      </div>
    </Card>
  );
};
export default Advertisement;
