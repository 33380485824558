import { AUTH_API, makeRequest, ServiceResponse } from "./apiService";

export const getUserByToken = (token: string) => {
  makeRequest("GET", "/user", { token });
};

export const apiLogin = async (
  address_uuid: string,
  password: string
): Promise<ServiceResponse> => {
  return new Promise((resolve, reject) => {
    fetch(AUTH_API + "/login", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ address_uuid, password }),
    }).then(
      (response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((result) => {
            resolve(result);
          });
        } else {
          reject();
        }
      },
      (error) => {
        console.error(error);
        reject(error);
      }
    );
  });
};
