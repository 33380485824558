import "./admin-announcements.scss";
import { Outlet, useNavigate } from "react-router";
import Bulletin from "../../components/card/bulletin";
import UserHeader from "../../components/user-header";
import { headerConfigMapper } from "../../components/user-header/header-config";
import { useGlobalContext } from "../../hooks/context/globalContext/globalContext";
import PageContent from "../layout/components/page-content/page-content";
import Table from "../../components/table/table";
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare, faTrashCan } from "@fortawesome/free-solid-svg-icons";
const AdminAnnouncements = () => {
  // const [postList, setPostList] = useState<PostResponseType[]>([]);
  const {
    filteredAnnouncements,
    selectedAnnouncement,
    setSelectedAnnouncement,
    deleteAnnouncement,
  } = useGlobalContext();
  const [isDeleteAnnouncement, setIsDeleteAnnouncement] = useState(false);
  const [mappedAnnouncementData, setMappedAnnouncementData] = useState<any[]>(
    []
  );
  useEffect(() => {
    setMappedAnnouncementData(
      filteredAnnouncements.map((announcement) => ({
        visibility: announcement.visibility ?? "",
        image_url: announcement.image_url ?? "",
        title: announcement.title ?? "",
        subtitle: announcement.subtitle ?? "",
        content: announcement.content ?? "",
        created_at: announcement.created_at ?? "",
        uuid: announcement.uuid,
      }))
    );
  }, [filteredAnnouncements]);
  // console.log({ announcementList });
  const deleteRow = async () => {
    await deleteAnnouncement(selectedAnnouncement!.uuid);
    setIsDeleteAnnouncement(false);
  };
  const navigate = useNavigate();
  return (
    <div className="dashboard page-container">
      <UserHeader {...headerConfigMapper["admin_announcements"]} />
      <PageContent>
        {selectedAnnouncement ? (
          <div className="announcement-selected">
            <p>
              1 Announcement Selected{" "}
              <FontAwesomeIcon
                onClick={() => {
                  navigate(
                    `action/edit-announcement/${selectedAnnouncement?.uuid}`
                  );
                }}
                className="edit"
                icon={faPenToSquare}
              />
            </p>

            {isDeleteAnnouncement ? (
              <p
                onClick={() => setIsDeleteAnnouncement((prev) => !prev)}
                className="delete"
              >
                Cancel
              </p>
            ) : (
              <p
                onClick={() => setIsDeleteAnnouncement((prev) => !prev)}
                className="delete"
              >
                <FontAwesomeIcon icon={faTrashCan} />
                Delete
              </p>
            )}

            {isDeleteAnnouncement ? (
              <p onClick={() => deleteRow()} className="delete confirm">
                <FontAwesomeIcon icon={faTrashCan} />
                click here to confirm
              </p>
            ) : null}
          </div>
        ) : null}
        <Table
          selectedRow={selectedAnnouncement}
          setSelectedRow={setSelectedAnnouncement}
          deleteRow={isDeleteAnnouncement}
          columnNames={[
            {
              accessKey: "visibility",
              displayLabel: "Visibility",
              columnType: "visibility",
            },
            {
              accessKey: "image_url",
              displayLabel: "Image",
              columnType: "image",
            },
            { accessKey: "title", displayLabel: "Title" },
            { accessKey: "subtitle", displayLabel: "Subtitle" },
            { accessKey: "content", displayLabel: "Message" },
            {
              accessKey: "created_at",
              displayLabel: "Published",
              columnType: "date",
            },
          ]}
          data={mappedAnnouncementData}
        />
      </PageContent>
      <Outlet />
    </div>
  );
};
export default AdminAnnouncements;
