import {
  faCircleXmark,
  faPenToSquare,
  faTrashCan,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useCallback, useEffect, useRef, useState } from "react";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import { useGlobalContext } from "../../hooks/context/globalContext/globalContext";
import { useClickOutComponent } from "../../hooks/useClickOutComponent";
import { useAuth } from "../../hooks/context/authContext/authContext";

const ActionPage = () => {
  const ref = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  console.log(params.uuid);
  const {
    actionPageOpen,
    setActionPageOpen,
    selectedPost,
    setSelectedPost,
    deletePost,
  } = useGlobalContext();
  const { currentUser } = useAuth();
  const [isDeletePost, setIsDeletePost] = useState(false);
  const [navigationcounter, setNavigationCounter] = useState(1);

  const handleCloseActionPage = useCallback(() => {
    setSelectedPost(undefined);
    setActionPageOpen(false);
    navigate(-1 * navigationcounter);
  }, [setActionPageOpen, navigate]);
  // const [open, setOpen] = useState(false);
  useClickOutComponent(ref, handleCloseActionPage);

  useEffect(() => {
    setActionPageOpen(true);
    return () => {
      setActionPageOpen(false);
    };
  }, [setActionPageOpen]);
  const deleteBulletin = async () => {
    await deletePost(selectedPost!.uuid);
    setIsDeletePost(false);
    navigate("dashboard");
  };
  return (
    <div ref={ref} className={`action-page ${actionPageOpen ? "active" : ""}`}>
      <div className="action-page-header">
        <button className="close" onClick={handleCloseActionPage}>
          close
          <FontAwesomeIcon icon={faCircleXmark} />
        </button>
        {selectedPost?.user.uuid === currentUser?.uuid && (
          <div className="bulletin-action-buttons">
            {isDeletePost ? (
              <p
                onClick={() => setIsDeletePost((prev) => !prev)}
                className="delete"
              >
                Cancel
              </p>
            ) : (
              <p
                onClick={() => setIsDeletePost((prev) => !prev)}
                className="delete"
              >
                <FontAwesomeIcon icon={faTrashCan} />
                Delete
              </p>
            )}

            {isDeletePost ? (
              <p onClick={() => deleteBulletin()} className="delete confirm">
                <FontAwesomeIcon icon={faTrashCan} />
                click here to confirm
              </p>
            ) : null}
            {!location.pathname.includes("edit") ? (
              <button
                className="close"
                onClick={() => {
                  setNavigationCounter((prev) => prev + 1);
                  navigate(`edit-bulletin/${selectedPost?.uuid}`);
                }}
              >
                edit
                <FontAwesomeIcon icon={faPenToSquare} />
              </button>
            ) : null}
          </div>
        )}
      </div>
      <div className="action-page-content">
        <Outlet />
      </div>
    </div>
  );
};
export default ActionPage;
