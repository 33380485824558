import OpaqueBackground from "../../components/opaque-background";
import Header from "../../components/header";
import SearchAddress from "../../components/search-address";
import InputItem from "../../components/input-item";
import { Link, useNavigate } from "react-router-dom";
import { createAccount } from "../../services/apiService";
import { useCallback, useEffect, useState } from "react";
import { useGlobalContext } from "../../hooks/context/globalContext/globalContext";

const CreateAccount = () => {
  const navigate = useNavigate();

  const { selectedAddress, setSelectedAddress } = useGlobalContext();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [termsAndConditions, setTermsAndConditions] = useState(false);
  const [showErrors, setShowErrors] = useState(false);
  const [status, setStatus] = useState("");

  const resetFields = useCallback(() => {
    setSelectedAddress(null);
    setPassword("");
    setConfirmPassword("");
    setShowErrors(false);
  }, [setSelectedAddress, setPassword, setConfirmPassword, setShowErrors]);

  useEffect(() => {
    if (status === "error") {
      resetFields();
    }
  }, [status, resetFields]);

  const isValidForm = (): boolean => {
    return !!(
      selectedAddress?.uuid &&
      password &&
      termsAndConditions &&
      password === confirmPassword
    );
  };
  return (
    <div className="create-account">
      <OpaqueBackground>
        <Header />
        <section className="content">
          <p className="label">JOIN US</p>
          <h1>
            Create <br />
            Your Account
          </h1>
          <form
            onSubmit={async (event) => {
              try {
                event.preventDefault();
                setShowErrors(true);
                if (isValidForm()) {
                  setStatus("loading");
                  const res = await createAccount(
                    selectedAddress!.uuid,
                    password
                  );
                  if (res.success) {
                    setStatus("success");
                    navigate("/log-in");
                  } else {
                    setStatus("error");
                  }
                }
              } catch (error) {
                setStatus("error");
              }
            }}
          >
            <SearchAddress title={"address"} />
            <InputItem
              onChange={({ target }) => setPassword(target.value)}
              value={password}
              required={true}
              title="Password"
              type="password"
            />
            <InputItem
              onChange={({ target }) => setConfirmPassword(target.value)}
              value={confirmPassword}
              required={true}
              title="Confirm Password"
              type="password"
              errorMessage="Password does not match *"
              displayError={showErrors && password !== confirmPassword}
            />
            <span className="input-check">
              <input
                onChange={() => setTermsAndConditions((prev) => !prev)}
                checked={termsAndConditions}
                type="checkbox"
                required
              />
              <p>I have read and agree to the Terms & Conditions.</p>
            </span>
            <button disabled={status === "loading"} className="primary-button">
              Join Now
            </button>
          </form>
          <p className="login-paragraph">
            Have an account? <Link to="/log-in">Log in here.</Link>
          </p>
        </section>
      </OpaqueBackground>
    </div>
  );
};
export default CreateAccount;
