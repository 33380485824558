import ProfilePicture from "../../../profile-picture/profile-picture";

const ColumnTypeAd = ({
  imageUrl,
  adTitle,
}: {
  imageUrl: string;
  adTitle: string;
}) => {
  return (
    <>
      <div className="table-subcolumn">
        <ProfilePicture src={imageUrl} />
      </div>
      <div className="table-subcolumn">
        <p>{adTitle}</p>
      </div>
    </>
  );
};
export default ColumnTypeAd;
