import { Link } from "react-router-dom";
import Logo from "../../assets/img/logo.svg";
const Header = () => {
  return (
    <header className="header">
      <Link to="/" className="logo">
        <img src={Logo} alt="Hillcrest Estates logo" />
        <strong>Hillcrest Estates</strong>
      </Link>
      <nav className="auth">
        <Link to="/log-in">Login</Link>
        {/* <Link to="/create-account">Create Account</Link> */}
      </nav>
    </header>
  );
};
export default Header;
